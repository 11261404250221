var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-batch" },
    [
      _c("app-header", { attrs: { title: _vm.title, isShowBack: true } }),
      _c("div", { staticClass: "row-title" }, [
        _vm._v(_vm._s(_vm.opt.baseTitle ? _vm.opt.baseTitle : "基础信息"))
      ]),
      _c("app-form", {
        attrs: { opt: { form: _vm.opt.baseForm }, entry: "appDatchBaseForm" }
      }),
      _c("div", { staticClass: "row-title" }, [
        _vm._v(_vm._s(_vm.opt.batchTitle ? _vm.opt.batchTitle : "批量信息"))
      ]),
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.list, function(item, index) {
          return _c(
            "div",
            { key: item.entry, staticClass: "item" },
            [
              _vm.opt.showNumber
                ? _c("div", { staticClass: "list_item-number" }, [
                    _vm._v(_vm._s(index + 1) + ".")
                  ])
                : _vm._e(),
              _c("app-form", {
                ref: "appForm",
                refInFor: true,
                staticClass: "list_app-form",
                attrs: { opt: item.opt, entry: item.entry, isFor: true }
              }),
              _c(
                "div",
                { staticClass: "buts" },
                [
                  _c("el-button", {
                    attrs: {
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.addItem(index)
                      }
                    }
                  }),
                  _c("el-button", {
                    attrs: {
                      type: "danger",
                      icon: "el-icon-minus",
                      circle: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.delItem(index)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        0
      ),
      _c(
        "footer",
        { staticClass: "action-but" },
        _vm._l(_vm.buttons, function(item) {
          return _c(
            "div",
            { key: item.name },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: item.type ? item.type : "primary",
                    icon: item.icon
                  },
                  on: {
                    click: function($event) {
                      _vm.onAction(item.cb, item.isNotValidate)
                    }
                  }
                },
                [_vm._v(_vm._s(item.name))]
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }