<!--
 * @Description: 授权网关弹窗组件
 * @Author: YunShuTeam
 * @Date: 2022-12-17 12:29:00
-->
<template>
  <div class="select-cascader">
    <div class="cascader-confirm-box" @click="dialogShow">{{showText}}</div>
    <el-dialog title="授权网关" :visible.sync="dialogVisible" :close-on-click-modal="false" @close="onCancel">
      <div class="cont">
        <div class="lt">
          <div class="lt-top">
            <div class="lazy-cascader-search">
              <el-select v-model="houseNameKeyword" placeholder="请输入房源名称" filterable @change="onChangeSelectHouse"
                :clearable="true">
                <el-option v-for="(it,idx) in houseList" :key="it.houseId" :label="it.houseName" :value="idx">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="lt-cent" id="houseBox">
            <div class="stores-c">
              <div v-if="houseList.length == 0">
                <div>暂无数据</div>
              </div>
              <div v-else>
                <div class="stores-list" v-for="(item,index) in houseList" :key="item.houseId">
                  <div class="item">
                    <span class="txt clickStyle" @click="clickHouseItem(item,index)"
                      :style="{'color':clickHouseIndex == index ? '#30bac1':''}">{{getShopName(item.houseName)}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="center-top">
            <div class="lazy-cascader-search">
              <el-input v-model.trim="gatewayKeyword" placeholder="请输入网关名称" @input="gatewayInput(gatewayKeyword)"
                :clearable="true"></el-input>
              <!-- <el-select v-model="gatewayKeyword" placeholder="请输入网关名称" filterable @change="onChangeSelectGateway" @input="gatewayInput(gatewayKeyword)" :clearable="true">
                <el-option v-for="(it,idx) in gatewayList" :key="it.deviceId" :label="it.deviceName" :value="idx"></el-option>
              </el-select> -->
            </div>
          </div>
          <div class="select-all item">
            <img v-if="!gatewayKeyword" :src="getCheckBoxImg(isCheckSubAll)" class="img" @click="clickCheckSubAll" />
            <img v-if="gatewayKeyword" :src="getCheckBoxImg(isCheckSubAllFactor)" class="img"
              @click="clickCheckSubAll" />
            <span class="txt">全选</span>
          </div>
            <div class="center-cent" v-if="clickHouseIndex != -1" id="gatewayBox">
              <div class="stores-list" v-for="(item,index) in houseList[clickHouseIndex].gatewayList"
                :key="item.deviceId">
                <div class="item borderBottom clickStyle" v-if="!item.isHidden" @click="clickGatewayCheck(item,index)">
                  <img style="position: relative;top: -9px;" :src="getCheckBoxImg(item.isAuth)" class="img" />
                  <div>
                    <div class="txt" style="height: 20px;">{{item.deviceName}}</div>
                    <span class="txt" style="color: #888;font-size:13px;">{{item.deviceMac}}</span>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="bottom">
        <el-button @click="onCancel">取消</el-button>
        <el-button @click="onSure">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      props: {
        type: Object,
        default: () => {
          return {};
        }
      },
    },
    data() {
      return {
        //缓存
        houseList: [],
        cacheHouses: '[]',
        dialogVisible: false,
        showText: '编辑授权网关',
        isCheckSubAll: 0,
        clickHouseIndex: -1,
        houseNameKeyword: '',
        gatewayKeyword: '',
        authCounterList: [],
        gatewayList: [],
        isCheckSubAllFactor: 0,
      }
    },
    async created() {
      try {
        this.houseList = await this.props.queryHouses();
        if(this.$route.params.type == 'show') {
          this.showText = '查看授权网关';
        }else {
          this.showText = '编辑授权网关';
        }

      } catch (error) {}
    },

    methods: {
      // 房源下的网关数据
      async getGateWayData(item, index) {
        try {

          let res = await this.props.queryGateways(item, index);
          this.gatewayList = res;
          if (!this.houseList.length) {
            return
          }
          this.houseList.forEach((houseItem, houseIndex) => {
            if (item.houseId == houseItem.houseId && (!houseItem.gatewayList || houseItem.gatewayList.length == 0)) {
              houseItem.gatewayList = res;
            }
          })
          this.gatewayInput('');
          this.refreshIsCheckSubAll();
        } catch (error) {}
      },
      // 点击房源
      clickHouseItem(item, index) {
        this.clickHouseIndex = index;
        this.houseList[this.clickHouseIndex].isClick = true;
        this.gatewayKeyword = '';
        this.getGateWayData(item, index);
      },
      // 点击网关
      clickGatewayCheck(item, index) {
        this.houseList[this.clickHouseIndex].gatewayList[index].isAuth = item.isAuth == 0 || item.isAuth == 2 ? 1 : 0
        if (!this.gatewayKeyword) {
          this.refreshIsCheckSubAll()
        } else {
          this.gatewayInput(this.gatewayKeyword)
        }

      },
      // 刷新网关全选标志
      refreshIsCheckSubAll() {
        new Promise((resolve, reject) => {
          let authCount = 0
          this.houseList[this.clickHouseIndex].gatewayList.forEach((item, index) => {
            if (item.isAuth == 1) {
              authCount++
            }
          })
          if (authCount == 0) {
            this.isCheckSubAll = 0
          } else if (authCount == this.houseList[this.clickHouseIndex].gatewayList.length) {
            this.isCheckSubAll = 1
          } else {
            this.isCheckSubAll = 2
          }
          this.houseList[this.clickHouseIndex].isAuth = this.isCheckSubAll;
          this.$forceUpdate();
        })
      },

      // 网关名字长度处理
      getShopName(val) {
        let limit = 22
        if (val.length <= limit) {
          return val
        } else {
          let sub = val.substring(0, limit)
          return `${sub}...`
        }
      },
      getCheckBoxImg(val) {
        return require(!val ? '../../assets/ic_unselect.png' :
          val == 1 ? '../../assets/ic_select.png' : '../../assets/ic_select_half.png')
      },
      dialogShow() {
        this.dialogVisible = true
        // 请求房源及第一个房源下的网关数据
        this.queryHouses()
      },
      // 取消
      onCancel() {
        this.dialogVisible = false
        this.houseList = JSON.parse(this.cacheHouses)
        this.clickHouseIndex = -1
        this.isCheckSubAll = 0
      },
      // 确定
      onSure() {
        this.setSelectData()
        this.saveCacheHouses()
        this.dialogVisible = false
      },
      // 缓存保存的数据
      saveCacheHouses() {
        new Promise((resolve, reject) => {
          this.cacheHouses = JSON.stringify(this.houseList)
        })
      },

      setSelectData() {
        let list = [];
        this.houseList.forEach((houseItem, houseIndex) => {
          houseItem.gatewayAuthDtoList = [];
          let gatewayList = houseItem.gatewayList
          if (gatewayList && gatewayList.length > 0) {
            gatewayList.forEach((item, index) => {
              if (item.isAuth == 1) {
                houseItem.gatewayAuthDtoList.push({
                  deviceId: item.deviceId,
                  deviceMac: item.deviceMac,
                  deviceName: item.deviceName,
                })
              }
            })
          }
          if (houseItem.isClick) {
            list.push(houseItem)
          }
        })
        this.$emit("input", list)
      },

      async queryHouses() {
        try {
          if(this.houseList.length === 0) return;
          
          this.clickHouseItem(this.houseList[0], 0)
          this.setSelectData()
          this.saveCacheHouses()
        } catch (error) {}
      },


      clickCheckSubAll() {
        if (this.clickHouseIndex == -1) return
        if (!this.houseList[this.clickHouseIndex].gatewayList) return
        if (!this.gatewayKeyword) {
          this.isCheckSubAll = this.isCheckSubAll == 0 || this.isCheckSubAll == 2 ? 1 : 0;
          this.refreshSubList()
        } else {
          this.isCheckSubAllFactor = this.isCheckSubAllFactor == 0 || this.isCheckSubAllFactor == 2 ? 1 : 0;
          this.refreshSubListFactor();
        }

      },

      //未进行网关条件检索下的 点击全选网关
      unconditionalCheckAll() {
        if (this.clickHouseIndex == -1) return
        if (!this.houseList[this.clickHouseIndex].gatewayList) return
        this.isCheckSubAll = this.isCheckSubAll == 0 || this.isCheckSubAll == 2 ? 1 : 0
        this.refreshSubList()
      },


      //网关 进行关键字条件检索下的 点击全选网关
      factorCheckAll() {
        if (this.clickHouseIndex == -1) return
        if (!this.houseList[this.clickHouseIndex].gatewayList) return
        this.isCheckSubAll = this.isCheckSubAll == 0 || this.isCheckSubAll == 2 ? 1 : 0
        this.refreshSubListFactor()
      },
      // 进行网关键字条件检索下
      refreshSubListFactor() {
        new Promise((resolve, reject) => {
          this.houseList[this.clickHouseIndex].gatewayList.forEach((item, index) => {
            if (!item.isHidden) {
              item.isAuth = this.isCheckSubAllFactor == 1 ? 1 : (this.isCheckSubAllFactor == 2 ? item.isAuth :
                0)
            }
          })
        })
      },

      refreshSubList() {
        new Promise((resolve, reject) => {
          this.houseList[this.clickHouseIndex].isAuth = this.isCheckSubAll;
          this.houseList[this.clickHouseIndex].gatewayList.forEach((item, index) => {
            item.isAuth = this.isCheckSubAll == 1 ? 1 : (this.isCheckSubAll == 2 ? item.isAuth : 0)
          })
        })
      },
      onChangeSelectHouse(val) {
        let locateIndex = -1
        this.houseList.forEach((houseItem, houseIndex) => {
          if (val == houseIndex) {
            locateIndex = houseIndex
          }
        })
        if (locateIndex != -1) {
          this.clickHouseItem(this.houseList[locateIndex], locateIndex)
          document.getElementById('houseBox').scrollTop = 30 * locateIndex
          this.houseNameKeyword = ''
        }
      },
      onChangeSelectGateway(val) {
        let locateIndex = -1
        this.gatewayList.forEach((item, index) => {
          if (val == index) {
            locateIndex = index
          }
        })
        if (locateIndex != -1) {
          document.getElementById('gatewayBox').scrollTop = 30 * locateIndex
          this.gatewayKeyword = ''
        }
      },

      gatewayInput(val) {
        if (val) {
          let authCount = 0;
          let gatewayListLen = 0;
          this.houseList[this.clickHouseIndex].gatewayList.forEach(item => {
            let str = '';
            str = item.deviceName.match(val);
            if (str) {
              if ([str[0]].indexOf(val) != -1) {
                item.isHidden = false;
                gatewayListLen++;
                if (item.isAuth == 1) {
                  authCount++
                }
              } else {
                item.isHidden = true;
              }
            } else {
              item.isHidden = true;
            }
          })
          if (authCount == 0) {
            this.isCheckSubAllFactor = 0
          } else if (authCount == gatewayListLen) {
            this.isCheckSubAllFactor = 1
          } else {
            this.isCheckSubAllFactor = 2
          }
          this.houseList[this.clickHouseIndex].isAuth = this.isCheckSubAllFactor;
        } else {
          this.houseList[this.clickHouseIndex].gatewayList.forEach(item => {
            item.isHidden = false;
          })
          this.refreshIsCheckSubAll();
        }

      },
    }
  }
</script>

<style lang="scss" scoped>
  .select-cascader {
    .cascader-confirm-box {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      overflow: hidden;
      box-sizing: border-box;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* 限制在一个块元素显示的文本的行数 */
      -webkit-box-orient: vertical;
      /* 垂直排列 */
      word-break: break-all;
    }

    /* 内容自动换行 */
    .cont {
      display: flex;
      justify-content: flex-start;

      .lt {
        width: 50%;
        border: 1px solid rgba(228, 228, 228, 1);

        .lt-top {
          background: rgba(242, 242, 242, 1);
          height: 50px;
          display: flex;
          padding-left: 33px;
          // justify-content: center;
          align-items: center;
          border-bottom: 1px solid rgba(228, 228, 228, 1);

          .txt {
            height: 30px;
            line-height: 1;
            width: 80%;
            background-color: #fff;
            background-image: none;
            border-radius: 4px;
            border: 1px solid #dcdfe6;
            box-sizing: border-box;
            color: #606266;
            display: inline-block;
            font-size: inherit;
            height: 40px;
            line-height: 40px;
            outline: 0;
            padding: 0 15px;
          }
        }

        .lt-cent {
          height: 530px;
          // margin-left: 33px;
          margin-top: 10px;
          overflow-y: auto;

          .checkbox-txt {
            color: black;
          }

          .check-group-box {
            margin-left: 33px;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }

      .center {
        width: 50%;
        border: 1px solid rgba(228, 228, 228, 1);
        margin-left: 2%;

        .center-top {
          display: flex;
          // justify-content: center;
          padding-left: 12px;
          align-items: center;
          background: rgba(242, 242, 242, 1);
          height: 50px;
          border-bottom: 1px solid rgba(228, 228, 228, 1);
        }

        .center-cent {
          height: 530px;
          overflow-y: auto;
          padding-left: 19px;
        }
      }

      .check-box-left {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 5px;
        margin-top: 5px;
        margin-left: 22px;
      }

      .check-box {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 5px;
        margin-top: 5px;
      }

      .apartment-check-box {
        display: flex;
        height: 30px;
        align-items: center;
        cursor: pointer;
      }

      .check-box-line {
        display: flex;
        height: 42px;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid rgba(228, 228, 228, 1);
        padding-left: 12px;

        // span {
        //   margin-left: 8px;
        // }
      }

      .checkbox-label {
        cursor: pointer;
        padding-left: 8px;
      }
    }

    .bottom {
      width: 100%;
      text-align: center;
      margin-top: 15px;
    }

    .el-checkbox {
      display: block;
    }

    .lazy-cascader-search {
      width: 100%;
    }

    /deep/.el-autocomplete {
      width: 90%;
    }

    /deep/.el-checkbox__inner {
      width: 16px;
      height: 16px;
    }

    /deep/.el-checkbox__inner::after {
      height: 8px;
      left: 5px;
    }

    .el-checkbox__label {
      font-size: 15px;
      padding-left: 2px;
    }

    /deep/.el-autocomplete-suggestion li {
      font-size: 15px;
    }

    #houseBox .el-checkbox {
      line-height: 17px !important;
    }
  }

  @media only screen and (max-width: 500px) {

    //  /deep/.el-dialog {
    //     width: 90%;
    //   }
    .select-cascader {
      .el-dialog {
        width: 90% !important;
      }

      /deep/.el-dialog__body {
        padding: 5px 5px;
      }

      .cont .lt .lt-cent {
        margin-left: 5px;
      }

      .cont .center {
        width: 25%;
      }

      .cont .check-box-line {
        padding-left: 4px !important;
      }

      .cont {
        padding-left: 5px !important;
      }

      .cont .center .center-top {
        padding-left: 5px !important;
      }

      // /deep/.el-checkbox__label {
      //   padding-left: 2px;
      // }

      .cont .check-box-line span {
        padding-left: 2px;
      }

      .cont {
        padding-left: 5px;
      }

      .cont .checkbox-label {
        padding-left: 1px;
      }

      .cont .check-box-left {
        margin-left: 5px;
      }

      .cont .lt .lt-cent .check-group-box {
        margin-left: 10px;
      }
    }

    .el-autocomplete-suggestion__wrap {
      padding-bottom: 20px;
    }
  }

  .stores-c {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .stores-list {
      padding-left: 20px;
    }

  }

  .select-all {
    padding-left: 10px;
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #333333;

    .txt {
      margin-left: 10px;
      font-size: 15px;
      flex: 1;
    }

    .img {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  .clickStyle {
    cursor: pointer;
  }

  .borderBottom {
    border-bottom: 1px solid rgba(228, 228, 228, 1);

  }
</style>