var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-tree" },
    [
      _c("app-header", {
        attrs: { title: _vm.title, isShowRefresh: false, isShowBack: false }
      }),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-container",
                { staticClass: "tree-box" },
                [
                  _c(
                    "el-header",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.treeData.length && _vm.buttons.length,
                          expression: "treeData.length && buttons.length"
                        }
                      ],
                      staticClass: "box-header"
                    },
                    [
                      _vm._l(_vm.buttons, function(item) {
                        return _c(
                          "el-button",
                          {
                            key: item.id,
                            attrs: {
                              size: "medium",
                              icon: item.icon
                                ? item.icon
                                : item.type != undefined
                                  ? "el-icon-" +
                                    ["plus", "edit", "delete"][item.type]
                                  : "question",
                              type: item.style
                                ? item.style
                                : item.type != undefined
                                  ? ["primary", "success", "danger"][item.type]
                                  : "primary"
                            },
                            on: {
                              click: function($event) {
                                _vm.onButton(item)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  item.name
                                    ? item.name
                                    : item.type != undefined
                                      ? ["添加", "编辑", "删除"][item.type]
                                      : "未命名"
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      }),
                      _c("el-button", {
                        staticClass: "refresh",
                        attrs: { icon: "el-icon-refresh", circle: "" }
                      })
                    ],
                    2
                  ),
                  _c(
                    "el-main",
                    { staticStyle: { height: "500px", overflow: "auto" } },
                    [
                      _vm.treeData.length
                        ? _c("el-tree", {
                            attrs: {
                              data: _vm.treeData,
                              "highlight-current": "",
                              "node-key": "id",
                              "default-expand-all": "",
                              props: {
                                children: "children",
                                label: "label"
                              }
                            },
                            on: { "current-change": _vm.onCurrentChange }
                          })
                        : _vm._e(),
                      _vm.opt.table
                        ? _c("app-list", {
                            ref: "appList",
                            attrs: { opt: _vm.opt.table }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-col", { attrs: { span: 12 } }, [_vm._t("info")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }