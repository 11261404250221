import * as Sentry from "@sentry/vue";

const enableSentry = () => process.env.VUE_APP_SENTRY_ENABLE === 'true';

const codeWhiteList = () => [
  -1
];

const coreCodes = () => [
  500,
  999001,
];

const isWhiteList = (code) => codeWhiteList().includes(code);

const init = (Vue, router) => {
  if (!enableSentry()) {
    return;
  }

  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    normalizeDepth: 10,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded"
    ],
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", process.env.VUE_APP_DOMAIN, /^\//],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 1.0,
    logErrors: true,
    dist: process.env.VUE_APP_DIST,
    environment: process.env.VUE_APP_ENV === 'prod' ? 'production' : process.env.VUE_APP_ENV,
    release: `${process.env.VUE_APP_ENV}@${process.env.VUE_APP_VERSION}`
  });
}

const captureMessage = (message) => {
  if (!enableSentry()) {
    return;
  }
  Sentry.captureMessage(message);
}

const captureException = (exception) => {
  if (!enableSentry()) {
    return;
  }
  Sentry.captureException(exception);
}

const captureApiThenException = (url,dto,res) => {
  if (!enableSentry()) return;
  if (!res) return;

  try {
    let code = res.status === 200 ? res.data.code : res.status;
    let message = res.status === 200 ? res.data.msg || 'error' : res.statusText || 'error';
    if (code === 0 || isWhiteList(code)) return;

    let error = new Error(message);
    error.name = `接口异常[${url}]:[code=${code}]`;
    if (coreCodes().includes(code)) {
      Sentry.setExtra('isCoreUrl', true);
    }
    Sentry.setExtra('request',{url,dto});
    Sentry.setExtra('response',res);
    Sentry.captureException(error);
  } catch (e) {

  }
}

const captureApiCatchException = (url, dto, err) => {
  if (!enableSentry()) return;
  if (!err) return;

  try {
    let error = new Error(err.toString());
    error.name = `接口异常[${url}]`;
    Sentry.setExtra('request',{url,dto});
    Sentry.setExtra('response',err);
    Sentry.captureException(error);
  } catch (e) {

  }
}

export default {
  init,
  captureMessage,
  captureException,
  captureApiThenException,
  captureApiCatchException,
}
