<template>
  <div class="select-cascader-advert">
    <div class="cascader-confirm-box" @click="dialogShow">{{showText}}</div>
    <el-dialog title="广告应用范围" :visible.sync="dialogVisible" :close-on-click-modal="false" @close="cancelBtn">
      <div class="cont">
        <div class="lt">
          <div class="lt-top">
            <div class="lazy-cascader-search">
              <el-autocomplete v-model="keyword" :debounce=0 :fetch-suggestions="querySearch" @select="handleSelect"
                placeholder="请输入房源名称" :disabled="disabledFlag" :trigger-on-focus="false" :clearable="true">
              </el-autocomplete>
              <!-- <div class="empty" v-show="isSearchEmpty">{{ searchEmptyText }}</div> -->
            </div>
          </div>
          <div class="lt-cent" id="apartmentBox">
            <!-- 全选先注释 -->
            <div class="check-box-left">
              <el-checkbox :indeterminate="apartmentIndeterminate" v-model="checkApartmentAll"
                @change="handleApartmentAllChange"><span class="checkbox-label">全选</span>
              </el-checkbox>
            </div>
            <el-checkbox-group class="check-group-box" v-model="checkedApartment" @change="handlecheckedApartmentChange"
              :disabled="disabledFlag">
              <div v-for="(item,index) in apartmentList" :key="item.id" class="apartment-check-box">
                <el-checkbox :indeterminate="item.checkIndeterminate" :label="item.id" :key="item.id"
                  @change="checked=>handlecheckedApartmentOneChange(checked,item)">
                  <span class="checkbox-label" @click.prevent="apartmentSpanChange(item.id,index)"
                    :style="{'color':clickApartmentLabelId==item.id?'#30BAC1':'#606266'}">{{item.name}}</span>
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </div>
        <div class="center">
          <div class="center-top">
            <div class="check-box">
              <el-checkbox :indeterminate="buildIndeterminate" v-model="checkBuildAll" :disabled="disabledFlag"
                @change="handleBuildAllChange"><span class="checkbox-label">城市</span>
              </el-checkbox>
            </div>
          </div>
          <div class="center-cent">
            <el-checkbox-group v-model="checkedBuild" @change="handlecheckedBuildChange" :disabled="disabledFlag">
              <div v-for="(item,index) in buildList" :key="item.id" class="check-box-line">
                <el-checkbox :indeterminate="item.checkIndeterminate" :label="item.id" :key="item.id"
                  @change="checked=>handlecheckedBuildOneChange(checked,item)">
                  <span class="checkbox-label" @click.prevent="buildSpanChang(item.id,index)"
                    :style="{'color':clickBuildLabelId==item.id?'#30BAC1':'#606266'}">{{item.name}}</span>
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </div>
        <div class="rt">
          <div class="rt-top">
            <div class="check-box">
              <el-checkbox :indeterminate="roomIndeterminate" v-model="checkRoomAll" :disabled="disabledFlag"
                @change="handleRoomAllChange"><span class="checkbox-label">房源</span>
              </el-checkbox>
            </div>
          </div>
          <div class="rt-cent" id="roomBox">
            <el-checkbox-group v-model="checkedRoom" @change="handlecheckedRoomChange" :disabled="disabledFlag">
              <div v-for="item in roomList" :key="item.id" class="check-box-line">
                <el-checkbox :label="item.id" :key="item.id">
                  <span class="checkbox-label">{{item.name}}</span>
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="bottom" v-show="!disabledFlag">
        <el-button @click="cancelBtn">取消</el-button>
        <el-button @click="selectSure('sure')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    props: {
      // value: {
      //   type: Array,
      //   default: () => {
      //     return [];
      //   }
      // },
      separator: {
        type: String,
        default: "/"
      },
      placeholder: {
        type: String,
        default: "请选择"
      },
      filterable: {
        type: Boolean,
        default: true
      },
      clearable: Boolean,
      disabled: {
        type: Boolean,
        default: false
      },
      props: {
        type: Object,
        default: () => {
          return {};
        }
      },
      suggestionsPopperClass: {
        type: String,
        default: "suggestions-popper-class"
      },
    },
    data() {
      return {
        disabledFlag: this.props.disabled,
        allFlag: false, //返显是否全选 true为全选
        apartmentDataList: [],
        apartmentLeafDataList: [],
        showText: '',
        apartmentIdsArr: [], //公安账户返显房源
        keyword: '',
        searchEmptyText: '暂无数据',
        isSearchEmpty: false,
        checkApartmentAll: false,
        checkedApartment: [],
        apartmentIndeterminate: false,
        clickApartmentLabelId: '',
        clickApartmentLabelIndex: 0,
        checkBuildAll: false,
        checkedBuild: [],
        buildIndeterminate: false,
        clickBuildLabelId: '',
        clickBuildLabelIndex: 0,
        checkRoomAll: false,
        checkedRoom: [],
        roomIndeterminate: false,
        clickRoomLabelId: '',
        apartmentIdArr: [],
        buildIdArr: [],
        roomIdArr: [],
        dialogVisible: false,
        treeData: {},
        apartmentList: [],
        buildList: [],
        roomList: [],
        userApartmentList: [],
        apartmentSpanChangeFlag: false, //切换房源标识 不能操作太快
        userId: '',
      };
    },
    computed: {
      placeholderVisible() {
        if (this.current) {
          return this.current.length == 0;
        } else {
          return true;
        }
      }
    },
    watch: {
      value(v) {
        this.userApartmentList = v;
      },
    },
    created() {
      if (this.$route.params.type == "add") {
        this.userId = '';
      } else {
        this.userId = this.$route.params.id;
      }
      console.log('select-cascader-advert created')
      this.getApartmentList();
      this.apartmentList = [];
      this.buildList = [];
      this.roomList = [];
      this.userApartmentList = [];
      this.checkApartmentAll = false;
      this.checkedApartment = [];
      this.apartmentIndeterminate = false;
      this.clickApartmentLabelId = '';
      this.clickApartmentLabelIndex = 0;
      this.checkBuildAll = false;
      this.checkedBuild = [];
      this.buildIndeterminate = false;
      this.clickBuildLabelId = '';
      this.clickBuildLabelIndex = 0;
      this.checkRoomAll = false;
      this.checkedRoom = [];
      this.roomIndeterminate = false;
      this.clickRoomLabelId = '';
      this.apartmentIdArr = [];
      this.buildIdArr = [];
      this.roomIdArr = [];
      this.treeData = {};
      this.apartmentLeafDataList = [];
      this.showText = '';
      this.apartmentSpanChangeFlag = false;
      this.props.getAllFlag(data => {
        this.allFlag = data;
      });
    },
    activated() {
      console.log('select-cascader-advert activated')
      // this.getApartmentList();
    },
    mounted() {
      console.log('select-cascader-advert mounted')
    },
    methods: {
      dialogShow() {
        this.dialogVisible = true;
        //全选的返显--点击确定之后
        if (this.treeData.checkedApartment.length == this.treeData.apartmentIdArr.length && this.treeData.apartmentIdArr
          .length > 0) {
          this.checkApartmentAll = true;
          this.apartmentIndeterminate = false;
        } else if (this.treeData.checkedApartment.length == 0) {
          this.checkApartmentAll = false;
          this.apartmentIndeterminate = false;
        } else {
          this.checkApartmentAll = false;
          this.apartmentIndeterminate = true;
        }
        this.$nextTick(() => {
          document.getElementById('apartmentBox').scrollTop = 30 * this.clickApartmentLabelIndex; //跳转到具体房源
        })
      },
      //搜索
      querySearch(query, callback) {
        let _this = this;
        _this.keyword = query;
        this.$nextTick(() => {
          _this.props.querySearchAsync(query, list => {
            if (_this.keyword == list.queryString) {
              callback(list.result);
            }
          });

        })

      },
      handleSelect(item) {
        let _this = this;

        let params = {
          "city": "",
          "province": "",
          "type": "",
          "apartmentId": item.label
        }
        this.post("/landlord-service/advertiseInfo/detail/" + this
          .$route.params.id, params).then(
          res => {
            res.adApartments.forEach(it1 => {
              it1.citys.forEach((it2, idx2) => {
                it2.apartmentIds.forEach((it3, idx3) => {
                  if (it3.id == item.label) {
                    _this.treeData.apartmentList.forEach((apartmentItem, apartmentIndex) => {
                      if (apartmentItem.id == it1.code) {
                        _this.$nextTick(() => {
                          document.getElementById('apartmentBox').scrollTop = 30 *
                            apartmentIndex; //跳转到具体房源
                        })
                        _this.apartmentSpanChange(apartmentItem.id, apartmentIndex, idx2, idx3);
                        _this.keyword = '';

                      }
                    })
                  }
                })
              })
            })
            // resolve(result);
          })
      },
      selectSure(val) {
        this.userApartmentList = [];
        if (val == 'sure') {
          if (this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList && this.treeData.apartmentList[this
              .clickApartmentLabelIndex].buildList.length > 0) {
            this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
              'roomList', this.roomList);
            this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
              'roomIdArr', this.roomIdArr);
            this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
              'checkedRoom', this.checkedRoom);
            this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
              'checkIndeterminate', this.roomIndeterminate);
            this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex],
              'checkedBuild', this.checkedBuild);
            this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex],
              'checkIndeterminate', this.buildIndeterminate);
          }
          this.$set(this.treeData,
            'checkedApartment', this.checkedApartment);
          this.dialogVisible = false;
        }
        let selectText = '';
        let selectText2 = '';
        this.treeData.apartmentList.forEach(apartItem => {
          let apartmentBuildingItemList = [];
          //半选或全选
          if (this.treeData.checkedApartment
            .indexOf(apartItem.id) != -1 || apartItem.checkIndeterminate) {
            if (apartItem.buildList && apartItem.buildList.length > 0) {
              apartItem.buildList.forEach(buildItem => {
                let apartmentRoomItemList = [];
                if (buildItem.checkIndeterminate || buildItem.checkedRoom.length > 0 || apartItem.checkedBuild
                  .indexOf(buildItem.id) != -1) {
                  if (buildItem.checkedRoom) {
                    buildItem.checkedRoom.forEach(roomItem => {
                      buildItem.roomList.forEach(roomListItem => {
                        if (roomListItem.id == roomItem) {
                          apartmentRoomItemList.push({
                            id: roomListItem.id,
                            name: roomListItem.name,
                          })
                          if (selectText) {
                            selectText = selectText + '、' + apartItem.name + '/' + buildItem.name +
                              '/' + roomListItem.name;
                          } else {
                            selectText = apartItem.name + '/' + buildItem.name + '/' + roomListItem
                              .name;
                          }
                        }
                      })
                    })
                  }
                  apartmentBuildingItemList.push({
                    code: buildItem.id,
                    city: buildItem.name,
                    apartmentIds: apartmentRoomItemList,
                  })
                }
              })
            } else {
              if (selectText2) {
                selectText2 = selectText2 + '、' + apartItem.name;
              } else {
                selectText2 = apartItem.name;
              }
            }
            this.userApartmentList.push({
              code: apartItem.id,
              province: apartItem.name,
              citys: apartmentBuildingItemList,
            })
          }
        })
        this.$emit("input", this.userApartmentList);
        // if (this.checkApartmentAll && !this.apartmentIndeterminate) //全选
        // {
        //   this.$emit("input", ['0']);
        // } else {
        //   // this.$emit("input", this.userApartmentList);
        // }

        //保存this.apartmentLeafDataList数据，关闭时要重新渲染 因为跟上方数据格式不一样，所以要重新保存数据
        this.apartmentLeafDataList = [];
        this.treeData.apartmentList.forEach(apartItem => {
          let apartmentBuildingItemList = [];
          //半选或全选
          if (this.treeData.checkedApartment
            .indexOf(apartItem.id) != -1 || apartItem.checkIndeterminate) {
            if (apartItem.buildList) {
              apartItem.buildList.forEach(buildItem => {
                let apartmentRoomItemList = [];
                if (buildItem.checkIndeterminate || buildItem.checkedRoom.length > 0 || apartItem.checkedBuild
                  .indexOf(buildItem.id) != -1) {
                  if (buildItem.checkedRoom) {
                    buildItem.roomList.forEach(roomListItem => {
                      if (buildItem.checkedRoom.indexOf(roomListItem.id) != -1) {
                        apartmentRoomItemList.push({
                          id: roomListItem.id,
                          name: roomListItem.name,
                          leaf: true
                        })
                      } else {
                        apartmentRoomItemList.push({
                          id: roomListItem.id,
                          name: roomListItem.name,
                          leaf: false
                        })
                      }
                    })
                  }
                  apartmentBuildingItemList.push({
                    id: buildItem.id,
                    name: buildItem.name,
                    children: apartmentRoomItemList,
                  })
                } else {
                  buildItem.roomList.forEach(roomListItem => {
                    apartmentRoomItemList.push({
                      id: roomListItem.id,
                      name: roomListItem.name,
                      leaf: false
                    })
                  })
                  apartmentBuildingItemList.push({
                    id: buildItem.id,
                    name: buildItem.name,
                    children: apartmentRoomItemList,
                  })
                }
              })
            }

            this.apartmentLeafDataList.push({
              id: apartItem.id,
              name: apartItem.name,
              children: apartmentBuildingItemList,
            })
          }
        })
        this.showText = (this.allFlag && selectText) ? selectText + '...' : selectText;
        if (this.checkApartmentAll) {
          this.showText = '全选';
        }
        if (!this.showText) {
          this.showText = selectText2;
        }
      },
      cancelBtn() {
        let _this = this;
        let checkedApartment = [];
        let apartmentIndeterminate = false;
        let apartmentList = [];
        let apartmentIdArr = [];
        let checkedApartmentNum = 0;
        this.apartmentDataList.forEach((item, index) => {
          let buildList = [];
          let buildIdArr = [];
          let checkedBuild = [];
          this.apartmentLeafDataList.forEach(it => {
            if (item.id == it.id) {
              checkedApartment.push(it.id)
              it.children.forEach((it2, idx2) => { //楼栋
                let buildIndeterminate = false;
                let checkedRoom = [];
                let roomList = [];
                let roomIdArr = [];
                it2.children.forEach((
                  roomItem) => { //房间
                  roomList.push({
                    id: roomItem.id,
                    name: roomItem.name,
                  });
                  roomIdArr.push(roomItem.id)
                  if (roomItem.leaf == true) {
                    checkedRoom.push(roomItem.id);
                  }
                })
                if (checkedRoom.length > 0 && checkedRoom.length == it2
                  .children
                  .length) { //判断楼栋是否全选
                  checkedBuild.push(it2.id);
                } else if (checkedRoom.length > 0 && checkedRoom.length <
                  it2
                  .children.length) {
                  buildIndeterminate = true;
                }
                buildList.push({
                  roomList: roomList,
                  roomIdArr: roomIdArr,
                  id: it2.id,
                  name: it2.name,
                  checkedRoom: checkedRoom,
                  checkIndeterminate: buildIndeterminate,
                });
                buildIdArr.push(it2.id);
              })
            }
          })
          //不是全选  有半选
          let buildIndeterminateNum = 0;
          buildList.forEach(buildItem => {
            if (buildItem.checkIndeterminate) {
              buildIndeterminateNum++;
            }
          })
          if (buildList.length == 0) {
            item.buildList.forEach(it3=>{
              it3.checkedRoom=[];
              it3.checkIndeterminate=false;
            })
            apartmentList.push({
              id: item.id,
              name: item.name,
              buildList:item.buildList,
              buildIdArr: item.buildIdArr,
              checkedBuild: [],
              checkIndeterminate: false
            });
          } else {
            apartmentList.push({
              id: item.id,
              name: item.name,
              buildList: buildList.length > 0 ? buildList : null,
              buildIdArr: buildIdArr,
              checkedBuild: checkedBuild,
              checkIndeterminate: (checkedBuild.length > 0 && checkedBuild
                .length <
                buildList.length) || buildIndeterminateNum > 0
            });
          }

          apartmentIdArr.push(item.id)
          if (buildList.length > 0 && buildList.length == checkedBuild.length) {
            checkedApartmentNum++;
          }
          if (checkedApartment.length > 0 && item.id == checkedApartment[
              0] && apartmentList[index].buildList) { //返显楼栋和房间
            this.buildList = apartmentList[index].buildList;
            this.checkBuildAll = apartmentList[index].checkedBuild.length > 0 &&
              apartmentList[index].buildIdArr.length == apartmentList[index]
              .checkedBuild.length;
            this.buildIndeterminate = apartmentList[index].checkIndeterminate;
            this.checkedBuild = apartmentList[index].checkedBuild;
            this.clickBuildLabelId = apartmentList[index].buildList[0].id;
            this.clickBuildLabelIndex = 0; //修改
            this.buildIdArr = apartmentList[index].buildIdArr;
            this.clickApartmentLabelIndex = index;
            this.roomList = apartmentList[index].buildList[0].roomList;
            this.checkRoomAll = apartmentList[index].buildList[0].checkedRoom.length >
              0 && apartmentList[index].buildList[0].roomIdArr.length == apartmentList[
                index].buildList[0].checkedRoom.length;
            this.roomIndeterminate = apartmentList[index].buildList[0]
              .checkIndeterminate;
            this.checkedRoom = apartmentList[index].buildList[0].checkedRoom;
            this.roomIdArr = apartmentList[index].buildList[0].roomIdArr;
          }
        })
        //返显房源
        let apartmentIndeterminateNum = 0;
        apartmentList.forEach(element => {
          if (element.checkIndeterminate) {
            apartmentIndeterminateNum++;
          }
        });
        if (!checkedApartment[0] && !apartmentIndeterminate) //没有被选中
        {
          this.buildList = [];
          this.checkBuildAll = [];
          this.buildIndeterminate = false;
          this.checkedBuild = [];
          this.clickBuildLabelId = '';
          this.clickBuildLabelIndex = 0; //修改
          this.buildIdArr = [];
          this.clickApartmentLabelIndex = 0;
          this.clickApartmentLabelId = '';
          this.roomList = [];
          this.checkRoomAll = [];
          this.roomIndeterminate = false;
          this.checkedRoom = [];
          this.roomIdArr = [];

        }
        this.treeData.checkedApartment = checkedApartment;
        this.treeData.apartmentIndeterminate = (checkedApartmentNum > 0 &&
          checkedApartmentNum <
          apartmentList.length) || apartmentIndeterminateNum > 0;
        this.treeData.apartmentList = apartmentList;
        this.treeData.apartmentIdArr = apartmentIdArr;
        this.treeData.checkApartmentAll = (checkedApartmentNum > 0 &&
          checkedApartmentNum ==
          apartmentList.length);
        this.checkApartmentAll = (checkedApartmentNum > 0 && checkedApartmentNum ==
          apartmentList.length);
        this.apartmentIndeterminate = (checkedApartmentNum > 0 &&
          checkedApartmentNum <
          apartmentList.length) || apartmentIndeterminateNum > 0;
        this.checkedApartment = checkedApartment;
        this.apartmentList = apartmentList;
        this.apartmentIdArr = apartmentIdArr;
        if (this.checkedApartment.length > 0) {
          this.clickApartmentLabelId = this.checkedApartment[0];
        }
        this.apartmentIdsArr = checkedApartment;

        this.dialogVisible = false;
      },
      handleApartmentAllChange(val) {
        this.apartmentIndeterminate = false;
        this.checkBuildAll = val;
        this.checkedApartment = [];
        this.checkedBuild = [];
        this.checkedRoom = [];
        if (val) {
          this.apartmentIdArr.forEach((item, index) => {
            this.$set(this.checkedApartment, index, item);
          })
          this.apartmentList.forEach((item, index) => {
            item.checkIndeterminate = false;
          })
          this.buildIdArr.forEach((item, index) => {
            this.$set(this.checkedBuild, index, item)
          })
          this.buildList.forEach((item, index) => {
            item.checkIndeterminate = false;
          })
          this.roomIdArr.forEach((item, index) => {
            this.$set(this.checkedRoom, index, item)
          })
        }
        this.buildIndeterminate = false;
        this.checkRoomAll = val;
        this.roomIndeterminate = false;
        this.treeData.apartmentList.forEach((apartmentItem, apartmentIndex) => {
          if (apartmentItem.buildList) {
            apartmentItem.checkIndeterminate = false;
            apartmentItem.checkedBuild = [];
            if (val) {
              apartmentItem.checkedBuild = apartmentItem.buildIdArr;
            }
            apartmentItem.buildList.forEach(buildItem => {
              buildItem.checkIndeterminate = false;
              buildItem.checkedRoom = [];
              if (val) {
                buildItem.checkedRoom = buildItem.roomIdArr;
              }
            })
          }
        })
      },
      handlecheckedApartmentChange(value) {
        let checkedCount = value.length;
        this.checkApartmentAll = checkedCount === this.apartmentList.length;
        let indeterminateNum = 0; //计算child有多少个indeterminate为true
        this.apartmentList.forEach(item => {
          if (item.checkIndeterminate) {
            indeterminateNum++
          }
        })
        this.apartmentIndeterminate = (checkedCount > 0 && checkedCount < this.apartmentList.length) ||
          indeterminateNum > 0;

      },
      handlecheckedApartmentOneChange(checked, item) {
        let _this = this;
        if (this.apartmentSpanChangeFlag) {
          return this.$message({
            message: "操作频繁，请稍后再试",
            type: "warning"
          });
        }
        this.apartmentSpanChangeFlag = true;
        if (this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList && this.treeData.apartmentList[this
            .clickApartmentLabelIndex].buildList.length > 0 && this.clickApartmentLabelId) {
          this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
            'roomList', this.roomList);
          this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
            'roomIdArr', this.roomIdArr);
          this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
            'checkedRoom', this.checkedRoom);
          this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
            'checkIndeterminate', this.roomIndeterminate);
          this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex],
            'checkedBuild', this.checkedBuild);
        }
        // 改变treeData
        this.treeData.apartmentList.forEach((apartmentItem, apartmentIndex) => {
          if (apartmentItem.id == item.id) {
            this.clickApartmentLabelId = item.id;
            this.clickApartmentLabelIndex = apartmentIndex;
            if (apartmentItem.buildList) {
              apartmentItem.checkIndeterminate = false;
              apartmentItem.checkedBuild = [];
              if (checked) {
                apartmentItem.checkedBuild = apartmentItem.buildIdArr;
              }
              apartmentItem.buildList.forEach(buildItem => {
                buildItem.checkIndeterminate = false;
                buildItem.checkedRoom = [];
                if (checked) {
                  buildItem.checkedRoom = buildItem.roomIdArr;
                }
              })
              if (apartmentItem.buildList && apartmentItem.buildList.length > 0) {
                let getBuild = apartmentItem.buildList[0];
                let getApartment = apartmentItem;
                this.clickBuildLabelIndex = 0; //默认显示第一个
                this.clickBuildLabelId = getBuild.id;
                this.roomList = getBuild.roomList;
                this.roomIdArr = getBuild.roomIdArr;
                this.buildList = getApartment.buildList;
                this.buildIdArr = getApartment.buildIdArr;
                this.roomIndeterminate = false;
                this.buildIndeterminate = false;
                if (checked) {
                  this.checkedRoom = getBuild.roomIdArr;
                  this.checkRoomAll = true;
                  this.checkedBuild = getApartment.buildIdArr;
                  this.checkBuildAll = true;
                } else {
                  this.checkedRoom = [];
                  this.checkedBuild = [];
                  this.checkRoomAll = false;
                  this.checkBuildAll = false;
                }

              } else {
                this.clickBuildLabelIndex = 0; //默认显示第一个
                this.clickBuildLabelId = '';
                this.roomList = [];
                this.roomIdArr = [];
                this.checkedRoom = [];
                this.roomIndeterminate = false;
                this.checkRoomAll = [];
                this.buildList = [];
                this.buildIdArr = [];
                this.checkedBuild = [];
                this.buildIndeterminate = false;
                this.checkBuildAll = [];
              }
              this.apartmentSpanChangeFlag = false;
            } else {
              let params = {
                apartmentId: item.id,
              }
              if (this.allFlag) {
                params.type = 'all'
              }
              this.props.twoLoad(params).then(result => {
                let buildList = [];
                let buildIdArr = [];
                let checkedBuild = [];
                let buildIndeterminate = false;
                if (result[0] && result[0].children) {
                  result[0].children.forEach((it2, idx2) => { //楼栋
                    let checkedRoom = [];
                    let roomList = [];
                    let roomIdArr = [];
                    it2.children.forEach((roomItem) => { //房间
                      roomList.push({
                        id: roomItem.id,
                        name: roomItem.name,
                      });
                      roomIdArr.push(roomItem.id)
                      checkedRoom.push(roomItem.id);
                    })
                    buildList.push({
                      roomList: roomList,
                      roomIdArr: roomIdArr,
                      id: it2.id,
                      name: it2.name,
                      checkedRoom: checkedRoom,
                      checkIndeterminate: false,
                    });
                    buildIdArr.push(it2.id);
                    checkedBuild.push(it2.id);
                  })
                }
                if (buildList && buildList.length > 0) {
                  let getBuild = buildList[0];
                  this.clickBuildLabelIndex = 0; //默认显示第一个
                  this.clickBuildLabelId = getBuild.id;
                  this.roomList = getBuild.roomList;
                  this.roomIdArr = getBuild.roomIdArr;
                  this.buildList = buildList;
                  this.buildIdArr = buildIdArr;
                  this.roomIndeterminate = false;
                  this.buildIndeterminate = false;
                  if (checked) {
                    this.checkedRoom = getBuild.roomIdArr;
                    this.checkRoomAll = true;
                    this.checkedBuild = buildIdArr;
                    this.checkBuildAll = true;
                  } else {
                    this.checkedRoom = [];
                    this.checkedBuild = [];
                    this.checkRoomAll = false;
                    this.checkBuildAll = false;
                  }

                } else {
                  this.clickBuildLabelIndex = 0; //默认显示第一个
                  this.clickBuildLabelId = '';
                  this.roomList = [];
                  this.roomIdArr = [];
                  this.checkedRoom = [];
                  this.roomIndeterminate = false;
                  this.checkRoomAll = [];
                  this.buildList = [];
                  this.buildIdArr = [];
                  this.checkedBuild = [];
                  this.buildIndeterminate = false;
                  this.checkBuildAll = [];
                }
                this.$set(this.treeData.apartmentList[apartmentIndex], 'buildList', buildList);
                this.$set(this.treeData.apartmentList[apartmentIndex], 'buildIdArr', buildIdArr);
                this.$set(this.treeData.apartmentList[apartmentIndex], 'checkedBuild', checkedBuild);
                this.$set(this.treeData.apartmentList[apartmentIndex], 'checkIndeterminate', false);
                this.apartmentSpanChangeFlag = false;
              })
            }
          }
        })
      },
      apartmentSpanChange(val, index, buildIndex, roomIndex) {
        if (this.apartmentSpanChangeFlag) {
          return this.$message({
            message: "操作频繁，请稍后再试",
            type: "warning"
          });
        }
        this.apartmentSpanChangeFlag = true;
        if (this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList && this.treeData.apartmentList[this
            .clickApartmentLabelIndex].buildList.length > 0 && this.clickApartmentLabelId) {
          this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
            'roomList', this.roomList);
          this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
            'roomIdArr', this.roomIdArr);
          this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
            'checkedRoom', this.checkedRoom);
          this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
            'checkIndeterminate', this.roomIndeterminate);
          this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex],
            'checkedBuild', this.checkedBuild);
        }
        this.clickApartmentLabelId = val;
        this.clickApartmentLabelIndex = index;
        if (this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList == null) {
          this.getBuildingOptions(val, this.treeData.apartmentList[this.clickApartmentLabelIndex].name, buildIndex,
            roomIndex);
        } else {
          if (this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList && this.treeData.apartmentList[
              this.clickApartmentLabelIndex].buildList.length > 0) {
            let getBuild;
            let getApartment = this.treeData.apartmentList[this.clickApartmentLabelIndex];
            if (buildIndex) {
              this.clickBuildLabelIndex = buildIndex; //默认显示第一个
              getBuild = this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[buildIndex];
            } else {
              this.clickBuildLabelIndex = 0; //默认显示第一个
              getBuild = this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[0];
            }
            this.clickBuildLabelId = getBuild.id;
            this.roomList = getBuild.roomList;
            this.roomIdArr = getBuild.roomIdArr;
            this.checkedRoom = getBuild.checkedRoom;
            this.roomIndeterminate = getBuild.checkIndeterminate;
            this.checkRoomAll = getBuild.checkedRoom.length > 0 && getBuild.checkedRoom.length == getBuild
              .roomList
              .length;
            this.buildList = getApartment.buildList;
            this.buildIdArr = getApartment.buildIdArr;
            this.checkedBuild = getApartment.checkedBuild;
            this.buildIndeterminate = getApartment.checkIndeterminate;
            this.checkBuildAll = getApartment.checkedBuild.length > 0 && getApartment.checkedBuild.length ==
              getApartment.buildList.length;
            if (roomIndex) {
              this.$nextTick(() => {
                document.getElementById('roomBox').scrollTop = 43 * roomIndex; //第三列跳转到具体房源
              })
            }

          } else {
            if (buildIndex) {
              this.clickBuildLabelIndex = buildIndex; //默认显示第一个
            } else {
              this.clickBuildLabelIndex = 0; //默认显示第一个
            }
            this.clickBuildLabelId = '';
            this.roomList = [];
            this.roomIdArr = [];
            this.checkedRoom = [];
            this.roomIndeterminate = false;
            this.checkRoomAll = [];
            this.buildList = [];
            this.buildIdArr = [];
            this.checkedBuild = [];
            this.buildIndeterminate = false;
            this.checkBuildAll = [];
            if (roomIndex) {
              this.$nextTick(() => {
                document.getElementById('roomBox').scrollTop = 43 * roomIndex; //第三列跳转到具体房源
              })
            }
          }
          this.apartmentSpanChangeFlag = false;
        }
      },
      handlecheckedBuildOneChange(checked, item) {
        this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
          'roomList', this.roomList);
        this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
          'roomIdArr', this.roomIdArr);
        this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
          'checkedRoom', this.checkedRoom);
        this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
          'checkIndeterminate', this.roomIndeterminate);
        this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList.forEach((it, index) => {
          if (item.id == it.id) {
            this.clickBuildLabelId = it.id;
            this.clickBuildLabelIndex = index;
            this.$set(it, 'checkIndeterminate', false);
            if (checked) {
              this.$set(it, 'checkedRoom', it.roomIdArr);
            } else {
              this.$set(it, 'checkedRoom', []);
            }
            this.roomList = it.roomList;
            this.roomIdArr = it.roomIdArr;
            this.roomIndeterminate = false;
            if (checked) {
              this.checkedRoom = it.checkedRoom;
              this.checkRoomAll = true;
            } else {
              this.checkedRoom = [];
              this.checkRoomAll = false;
            }
          }
        })
      },
      buildSpanChang(val, index) {
        this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
          'roomList', this.roomList);
        this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
          'roomIdArr', this.roomIdArr);
        this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
          'checkedRoom', this.checkedRoom);
        this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this.clickBuildLabelIndex],
          'checkIndeterminate', this.roomIndeterminate);
        this.$nextTick(() => {
          this.clickBuildLabelId = val;
          this.clickBuildLabelIndex = index;
          let getBuild = this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList[this
            .clickBuildLabelIndex];
          this.roomList = getBuild.roomList;
          this.roomIdArr = getBuild.roomIdArr;
          this.checkedRoom = getBuild.checkedRoom;
          this.roomIndeterminate = getBuild.checkIndeterminate;
          this.checkRoomAll = getBuild.checkedRoom.length > 0 && getBuild.checkedRoom.length == getBuild
            .roomList
            .length;
        })
      },

      handleBuildAllChange(val) {
        this.checkedBuild = [];
        if (val) {
          this.buildIdArr.forEach(item => {
            this.checkedBuild.push(item)
          })
        }
        this.buildIndeterminate = false;
        //改变楼栋联动房源
        if (this.checkBuildAll) {

          if (this.checkedApartment.indexOf(this.apartmentList[this.clickApartmentLabelIndex]['id']) == -1) {
            this.checkedApartment.push(this.apartmentList[this.clickApartmentLabelIndex]['id']);
          }
        } else {
          this.checkedApartment.forEach((item, index) => {
            if (item == this.apartmentList[this.clickApartmentLabelIndex]['id']) {
              this.checkedApartment.splice(index, 1);
            }
          })
        }
        //选择全部楼栋，相应的房间也选择
        this.buildIdArr.forEach(item => {
          if (item == this.clickBuildLabelId) {
            this.handleRoomAllChange(val);
            this.checkRoomAll = val;
          }
        })
        this.treeData.apartmentList[this.clickApartmentLabelIndex].buildList.forEach(item => {
          item.checkIndeterminate = false;
          if (val) {
            item.checkedRoom = item.roomIdArr;
          } else {
            item.checkedRoom = [];
          }
        })

      },


      handleRoomAllChange(val) {
        this.checkedRoom = val ? this.roomIdArr : [];
        this.roomIndeterminate = false;
        //改变楼栋
        if (val) {
          if (this.checkedBuild.indexOf(this.buildList[this.clickBuildLabelIndex]['id']) == -1) {
            this.checkedBuild.push(this.buildList[this.clickBuildLabelIndex]['id']);
          }
        } else {
          this.checkedBuild.forEach((item, index) => {
            if (item == this.buildList[this.clickBuildLabelIndex]['id']) {
              this.checkedBuild.splice(index, 1)
            }
          })
        }
        this.buildList[this.clickBuildLabelIndex]['checkIndeterminate'] = this.roomIndeterminate;
        let buildCheckedCount = this.checkedBuild.length;
        this.checkBuildAll = buildCheckedCount === this.buildList.length;
        this.buildIndeterminate = (buildCheckedCount > 0 && buildCheckedCount < this.buildList.length) || this
          .roomIndeterminate;
        //改变房间联动房源
        if (this.checkBuildAll) {
          if (this.checkedApartment.indexOf(this.apartmentList[this.clickApartmentLabelIndex]['id']) == -1) {
            this.checkedApartment.push(this.apartmentList[this.clickApartmentLabelIndex]['id']);
          }
        } else {
          this.checkedApartment.forEach((item, index) => {
            if (item == this.apartmentList[this.clickApartmentLabelIndex]['id']) {
              this.checkedApartment.splice(index, 1);
            }
          })
        }
        let checkedCount2 = this.checkedApartment.length;
        this.checkApartmentAll = checkedCount2 === this.apartmentList.length;
        this.apartmentList[this.clickApartmentLabelIndex]['checkIndeterminate'] = this.buildIndeterminate;
        let indeterminateNum = 0; //计算child有多少个indeterminate为true
        this.apartmentList.forEach(item => {
          if (item.checkIndeterminate) {
            indeterminateNum++
          }
        })
        this.apartmentIndeterminate = (checkedCount2 > 0 && checkedCount2 < this.apartmentList.length) ||
          indeterminateNum > 0;
      },
      handlecheckedBuildChange(value) {
        //  debugger
        let checkedCount = value.length;
        this.checkBuildAll = checkedCount === this.buildList.length;
        let buildIndeterminateNum = 0; //计算child有多少个indeterminate为true
        this.buildList.forEach(item => {
          if (item.checkIndeterminate) {
            buildIndeterminateNum++
          }
        })
        this.buildIndeterminate = (checkedCount > 0 && checkedCount < this.buildList.length) || buildIndeterminateNum >
          0;
        this.apartmentList[this.clickApartmentLabelIndex]['checkIndeterminate'] = this.buildIndeterminate;
        //改变楼栋联动房源
        if (this.checkBuildAll) {
          if (this.checkedApartment.indexOf(this.apartmentList[this.clickApartmentLabelIndex]['id']) == -1) {
            this.checkedApartment.push(this.apartmentList[this.clickApartmentLabelIndex]['id']);
          }
        } else {
          this.checkedApartment.forEach((item, index) => {
            if (item == this.apartmentList[this.clickApartmentLabelIndex]['id']) {
              this.checkedApartment.splice(index, 1);
            }
          })
        }
        let checkedCount2 = this.checkedApartment.length;
        this.checkApartmentAll = checkedCount2 === this.apartmentList.length;
        this.apartmentList[this.clickApartmentLabelIndex]['checkIndeterminate'] = this.buildIndeterminate;
        let indeterminateNum = 0; //计算child有多少个indeterminate为true
        this.apartmentList.forEach(item => {
          if (item.checkIndeterminate) {
            indeterminateNum++
          }
        })
        this.apartmentIndeterminate = (checkedCount2 > 0 && checkedCount2 < this.apartmentList.length) ||
          indeterminateNum > 0;


      },
      handlecheckedRoomChange(value) {
        let checkedCount = value.length;
        this.checkRoomAll = checkedCount === this.roomList.length;
        this.roomIndeterminate = checkedCount > 0 && checkedCount < this.roomList.length;
        if (this.checkRoomAll) {
          if (this.checkedBuild.indexOf(this.buildList[this.clickBuildLabelIndex]['id']) == -1) {
            this.checkedBuild.push(this.buildList[this.clickBuildLabelIndex]['id']);
          }
        } else {
          //改变房间联动楼栋
          this.checkedBuild.forEach((item, index) => {
            if (item == this.buildList[this.clickBuildLabelIndex]['id']) {
              this.checkedBuild.splice(index, 1)
            }
          })
        }
        this.buildList[this.clickBuildLabelIndex]['checkIndeterminate'] = this.roomIndeterminate;
        let buildCheckedCount = this.checkedBuild.length;
        this.checkBuildAll = buildCheckedCount === this.buildList.length;
        let roomIndeterminateNum = 0; //计算child有多少个indeterminate为true
        this.buildList.forEach(item => {
          if (item.checkIndeterminate) {
            roomIndeterminateNum++
          }
        })
        this.buildIndeterminate = (buildCheckedCount > 0 && buildCheckedCount < this.buildList.length) ||
          roomIndeterminateNum > 0;
        this.apartmentList[this.clickApartmentLabelIndex]['checkIndeterminate'] = this.buildIndeterminate;
        //改变房间联动房源
        if (this.checkBuildAll) {
          if (this.checkedApartment.indexOf(this.apartmentList[this.clickApartmentLabelIndex]['id']) == -1) {
            this.checkedApartment.push(this.apartmentList[this.clickApartmentLabelIndex]['id']);
          }
        } else {
          this.checkedApartment.forEach((item, index) => {
            if (item == this.apartmentList[this.clickApartmentLabelIndex]['id']) {
              this.checkedApartment.splice(index, 1)
            }
          })
        }
        let checkedCount2 = this.checkedApartment.length;
        this.checkApartmentAll = checkedCount2 === this.apartmentList.length;
        let indeterminateNum = 0; //计算child有多少个indeterminate为true
        this.apartmentList.forEach(item => {
          if (item.checkIndeterminate) {
            indeterminateNum++
          }
        })
        this.apartmentIndeterminate = (checkedCount2 > 0 && checkedCount2 < this.apartmentList.length) ||
          indeterminateNum > 0;
      },
      getBuildingOptions(id, provinceName, buildIndex, roomIndex) {
        let _this = this;
        let params = {
          // apartmentId: id,
          "city": '',
          "province": provinceName,
          // "type": "all",
        }
        if (this.allFlag) {
          params.type = 'all'
        }
        this.props.twoLoad(params).then(result => {
            // this.post("landlord-service/room/querySelectedHomeInfo", params).then(
            //   result => {
            let buildList = [];
            let buildIdArr = [];
            let checkedBuild = [];
            let buildIndeterminate = false;
            let selectApartment = this.checkedApartment.indexOf(id) != -1 ? true : false; //该房源已是否被选中

            if (result && result[0] && result[0].children && result[0].children.length > 0) {
              if (selectApartment) {
                result[0].children.forEach((it2, idx2) => { //楼栋
                  let checkedRoom = [];
                  let roomList = [];
                  let roomIdArr = [];
                  it2.children.forEach((roomItem) => { //房间
                    roomList.push({
                      id: roomItem.id,
                      name: roomItem.name,
                    });
                    roomIdArr.push(roomItem.id)
                  })
                  buildList.push({
                    roomList: roomList,
                    roomIdArr: roomIdArr,
                    id: it2.id,
                    name: it2.name,
                    checkedRoom: roomIdArr,
                    checkIndeterminate: false,
                  });
                  buildIdArr.push(it2.id);


                  this.checkRoomAll = true;
                  this.roomIndeterminate = false;
                  let roomIdArr2 = [];
                  if (buildIndex) {
                    this.roomList = buildList[buildIndex].roomList;
                    buildList[buildIndex].roomList.forEach(item => {
                      roomIdArr2.push(item.id);
                    })
                  } else {
                    //返显房间 第一个房源第一个楼栋
                    this.roomList = buildList[0].roomList;
                    buildList[0].roomList.forEach(item => {
                      roomIdArr2.push(item.id);
                    })
                  }

                  this.roomIdArr = roomIdArr2;
                  this.checkedRoom = roomIdArr2;
                })
                this.buildList = buildList;
                this.checkBuildAll = true;
                this.buildIndeterminate = false;
                this.checkedBuild = buildIdArr;
                this.buildIdArr = buildIdArr;
              } else {
                result[0].children.forEach((it2, idx2) => { //楼栋
                  let checkedRoom = [];
                  let roomList = [];
                  let roomIdArr = [];
                  it2.children.forEach((roomItem) => { //房间
                    roomList.push({
                      id: roomItem.id,
                      name: roomItem.name,
                    });
                    roomIdArr.push(roomItem.id)
                    if (roomItem.leaf == true) {
                      checkedRoom.push(roomItem.id);
                    }
                  })
                  if (checkedRoom.length > 0 && checkedRoom.length == it2.children
                    .length) { //判断楼栋是否全选
                    checkedBuild.push(it2.id);
                  } else if (checkedRoom.length > 0 && checkedRoom.length < it2
                    .children.length) {
                    buildIndeterminate = true;
                  }
                  buildList.push({
                    roomList: roomList,
                    roomIdArr: roomIdArr,
                    id: it2.id,
                    name: it2.name,
                    checkedRoom: checkedRoom,
                    checkIndeterminate: buildIndeterminate,
                  });
                  buildIdArr.push(it2.id);
                  this.checkRoomAll = false;
                  this.roomIndeterminate = false;
                  this.checkedRoom = [];
                  let roomIdArr2 = [];
                  if (buildIndex) {
                    this.roomList = buildList[buildIndex].roomList;
                    buildList[buildIndex].roomList.forEach(item => {
                      roomIdArr2.push(item.id);
                    })
                  } else {
                    //返显房间 第一个房源第一个楼栋
                    this.roomList = buildList[0].roomList;
                    buildList[0].roomList.forEach(item => {
                      roomIdArr2.push(item.id);
                    })
                  }
                  this.roomIdArr = roomIdArr2;
                })
                this.buildList = buildList;
                this.checkBuildAll = false;
                this.buildIndeterminate = false;
                this.checkedBuild = [];
                this.buildIdArr = buildIdArr;
              }
            } else {
              this.buildList = [];
              this.checkBuildAll = false;
              this.buildIndeterminate = false;
              this.checkedBuild = [];
              this.buildIdArr = [];
              this.roomList = [];
              this.checkRoomAll = false;
              this.roomIndeterminate = false;
              this.checkedRoom = [];
              this.roomIdArr = [];
            }
            this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex], 'buildList', buildList);
            this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex], 'buildIdArr', buildIdArr);
            this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex], 'checkedBuild', []);
            this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex], 'checkIndeterminate', false)
            this.$set(this.treeData.apartmentList[this.clickApartmentLabelIndex], 'id', id);
            if (buildIndex) {
              this.clickBuildLabelIndex = buildIndex; //默认显示第一个
              if (buildList.length > 0) {
                this.clickBuildLabelId = buildList[buildIndex].id;
              }
            } else {
              this.clickBuildLabelIndex = 0; //默认显示第一个
              if (buildList.length > 0) {
                this.clickBuildLabelId = buildList[0].id;
              }
            }
            if (roomIndex) {
              this.$nextTick(() => {
                document.getElementById('roomBox').scrollTop = 43 * roomIndex; //第三列跳转到具体房源
              })
            }

            this.apartmentSpanChangeFlag = false;
            // console.log('this.checkBuildAll',this.checkBuildAll)
            // console.log('this.buildIndeterminate',this.buildIndeterminate)
            // console.log('this.checkedBuild',this.checkedBuild)
            // console.log('this.buildIdArr',this.buildIdArr)
            // console.log('this.roomList',this.roomList)
            // console.log('this.checkRoomAll',this.checkRoomAll)
            // console.log('this.roomIndeterminate',this.roomIndeterminate)
            // console.log('this.checkedRoom',this.checkedRoom)
            // console.log('this.roomIdArr',this.roomIdArr)
            //  console.log('this.apartmentList',this.apartmentList)
            // console.log('this.checkApartmentAll',this.checkApartmentAll)
            // console.log('this.apartmentIndeterminate',this.apartmentIndeterminate)
            // console.log('this.checkedApartment',this.checkedApartment)
            // console.log('this.apartmentIdArr',this.apartmentIdArr)
          })
          .catch(() => {
            this.apartmentSpanChangeFlag = false;
          })
      },
      //获取小区列表
      getApartmentList() {
        let _this = this;
        // _this.isLoading = true;
        // var dto = {
        //   pageNumber: "1",
        //   pageSize: "99999"
        // };
        return new Promise((resolve, reject) => {
          // debugger;
          this.props.oneLoad().then(res => {
            // this.post("landlord-service/apartment/queryApartmentInfo", dto).then(
            //   res => {
            // this.opt.form.forEach(data => {
            //工程人员授权弹框 公安账号授权一样的接口
            // if (data.key === "apartmentIds") {
            let arr = [{
              label: "全部",
              value: "0"
            }]
            res.forEach(item => {
              item.label = item.name;
              item.value = item.id;
            });
            let checkedApartment = [];
            let apartmentIndeterminate = false;
            let apartmentList = [];
            let apartmentIdArr = [];
            let checkedApartmentNum = 0;
            // _this.isLoading = false;
            res.forEach((item, index) => {
              let buildList = [];
              let buildIdArr = [];
              let checkedBuild = [];
              item.children.forEach((it2, idx2) => { //楼栋
                let buildIndeterminate = false;
                let checkedRoom = [];
                let roomList = [];
                let roomIdArr = [];
                it2.children.forEach((
                  roomItem) => { //房间
                  roomList.push({
                    id: roomItem.id,
                    name: roomItem.name,
                  });
                  roomIdArr.push(roomItem.id)
                  if (roomItem.leaf == true) {
                    checkedRoom.push(roomItem.id);
                  }
                })
                if (checkedRoom.length > 0 && checkedRoom.length == it2
                  .children
                  .length) { //判断楼栋是否全选
                  checkedBuild.push(it2.id);
                } else if (checkedRoom.length > 0 && checkedRoom.length <
                  it2
                  .children.length) {
                  buildIndeterminate = true;
                }
                buildList.push({
                  roomList: roomList,
                  roomIdArr: roomIdArr,
                  id: it2.id,
                  name: it2.name,
                  checkedRoom: checkedRoom,
                  checkIndeterminate: buildIndeterminate,
                });
                buildIdArr.push(it2.id);
              })

              apartmentList.push({
                id: item.id,
                name: item.name,
                buildList: buildList,
                buildIdArr: buildIdArr,
                checkedBuild: checkedBuild,
                checkIndeterminate: false
              });
              apartmentIdArr.push(item.id);
            })
            this.apartmentDataList = apartmentList;
            this.treeData.checkedApartment = checkedApartment;
            this.treeData.apartmentIndeterminate = false;
            this.treeData.apartmentList = apartmentList;
            this.treeData.apartmentIdArr = apartmentIdArr;
            this.treeData.checkApartmentAll = false;
            this.checkApartmentAll = false;
            this.apartmentIndeterminate = false;
            this.checkedApartment = checkedApartment;
            this.apartmentList = apartmentList;
            this.apartmentIdArr = apartmentIdArr;
            this.buildList = apartmentList[0].buildList;
            this.checkBuildAll = false;
            this.buildIndeterminate = apartmentList[0].checkIndeterminate;
            this.checkedBuild = apartmentList[0].checkedBuild;
            this.clickBuildLabelId = apartmentList[0].buildList[0].id;
            this.clickBuildLabelIndex = 0; //修改
            this.buildIdArr = apartmentList[0].buildIdArr;
            this.clickApartmentLabelIndex = 0;
            this.clickApartmentLabelId = apartmentList[0].id;
            this.roomList = apartmentList[0].buildList[0].roomList;
            this.checkRoomAll = false;
            this.roomIndeterminate = apartmentList[0].buildList[0]
              .checkIndeterminate;
            this.checkedRoom = apartmentList[0].buildList[0].checkedRoom;
            this.roomIdArr = apartmentList[0].buildList[0].roomIdArr;
            console.log('this.treeData.apartmentList2222', this.treeData.apartmentList);
            if (this.userId) {
              let params = {
                userId: this.userId,
                "type": ""
              }
              if (this.allFlag) {
                params = {
                  apartmentIds: res,
                  type: 'all',
                  // firstFlag: true
                }
              }
              this.props.twoLoad(params).then(result => {

                let checkedApartment = [];
                let apartmentIndeterminate = false;
                let apartmentList = [];
                let apartmentIdArr = [];
                let checkedApartmentNum = 0;
                // _this.isLoading = false;
                res.forEach((item, index) => {
                  let buildList = [];
                  let buildIdArr = [];
                  let checkedBuild = [];
                  result.forEach(it => {
                    if (item.id == it.id) {
                      checkedApartment.push(it.id)
                      it.children.forEach((it2, idx2) => { //楼栋
                        let buildIndeterminate = false;
                        let checkedRoom = [];
                        let roomList = [];
                        let roomIdArr = [];
                        it2.children.forEach((
                          roomItem) => { //房间
                          roomList.push({
                            id: roomItem.id,
                            name: roomItem.name,
                          });
                          roomIdArr.push(roomItem.id)
                          if (roomItem.leaf == true) {
                            checkedRoom.push(roomItem.id);
                          }
                        })
                        if (checkedRoom.length > 0 && checkedRoom.length == it2
                          .children
                          .length) { //判断楼栋是否全选
                          checkedBuild.push(it2.id);
                        } else if (checkedRoom.length > 0 && checkedRoom.length <
                          it2
                          .children.length) {
                          buildIndeterminate = true;
                        }
                        buildList.push({
                          roomList: roomList,
                          roomIdArr: roomIdArr,
                          id: it2.id,
                          name: it2.name,
                          checkedRoom: checkedRoom,
                          checkIndeterminate: buildIndeterminate,
                        });
                        buildIdArr.push(it2.id);
                      })
                    }
                  })
                  //不是全选  有半选
                  let buildIndeterminateNum = 0;
                  buildList.forEach(buildItem => {
                    if (buildItem.checkIndeterminate) {
                      buildIndeterminateNum++;
                    }
                  })
                  if (buildList.length > 0) {
                    apartmentList.push({
                      id: item.id,
                      name: item.name,
                      buildList: buildList,
                      buildIdArr: buildIdArr,
                      checkedBuild: checkedBuild,
                      checkIndeterminate: (checkedBuild.length > 0 && checkedBuild
                        .length <
                        buildList.length) || buildIndeterminateNum > 0
                    });
                  } else {
                    apartmentList.push(this.treeData.apartmentList[index]);
                  }

                  apartmentIdArr.push(item.id)
                  if (buildList.length > 0 && buildList.length == checkedBuild.length) {
                    checkedApartmentNum++;
                  }
                  if (checkedApartment.length > 0 && item.id == checkedApartment[
                      0] && apartmentList[index].buildList) { //返显楼栋
                    this.buildList = apartmentList[index].buildList;
                    this.checkBuildAll = apartmentList[index].checkedBuild.length > 0 &&
                      apartmentList[index].buildIdArr.length == apartmentList[index]
                      .checkedBuild.length;
                    this.buildIndeterminate = apartmentList[index].checkIndeterminate;
                    this.checkedBuild = apartmentList[index].checkedBuild;
                    this.clickBuildLabelId = apartmentList[index].buildList[0].id;
                    this.clickBuildLabelIndex = 0; //修改
                    this.buildIdArr = apartmentList[index].buildIdArr;
                    this.clickApartmentLabelIndex = index;
                    this.roomList = apartmentList[index].buildList[0].roomList;
                    this.checkRoomAll = apartmentList[index].buildList[0].checkedRoom.length >
                      0 && apartmentList[index].buildList[0].roomIdArr.length == apartmentList[
                        index].buildList[0].checkedRoom.length;
                    this.roomIndeterminate = apartmentList[index].buildList[0]
                      .checkIndeterminate;
                    this.checkedRoom = apartmentList[index].buildList[0].checkedRoom;
                    this.roomIdArr = apartmentList[index].buildList[0].roomIdArr;

                  }
                })
                //返显房源
                let apartmentIndeterminateNum = 0;
                apartmentList.forEach(element => {
                  if (element.checkIndeterminate) {
                    apartmentIndeterminateNum++;
                  }
                });
                this.treeData.checkedApartment = checkedApartment;
                this.treeData.apartmentIndeterminate = (checkedApartmentNum > 0 &&
                  checkedApartmentNum <
                  apartmentList.length) || apartmentIndeterminateNum > 0;
                this.treeData.apartmentList = apartmentList;
                this.treeData.apartmentIdArr = apartmentIdArr;
                this.treeData.checkApartmentAll = (checkedApartmentNum > 0 &&
                  checkedApartmentNum ==
                  apartmentList.length);
                this.checkApartmentAll = (checkedApartmentNum > 0 && checkedApartmentNum ==
                  apartmentList.length);
                this.apartmentIndeterminate = (checkedApartmentNum > 0 &&
                  checkedApartmentNum <
                  apartmentList.length) || apartmentIndeterminateNum > 0;
                this.checkedApartment = checkedApartment;
                this.apartmentList = apartmentList;
                this.apartmentIdArr = apartmentIdArr;
                if (this.checkedApartment.length > 0) {
                  this.clickApartmentLabelId = this.checkedApartment[0];
                }

                // data.opt.list = arr.concat(res);
                this.apartmentIdsArr = checkedApartment;

                if (this.allFlag) {
                  this.treeData.checkedApartment = apartmentIdArr;
                  this.treeData.apartmentIndeterminate = false;
                  this.treeData.apartmentList = apartmentList;
                  this.treeData.apartmentIdArr = apartmentIdArr;
                  this.treeData.checkApartmentAll = true;
                  this.checkApartmentAll = true;
                  this.apartmentIndeterminate = false;
                  this.checkedApartment = apartmentIdArr;
                  this.apartmentList = apartmentList;
                  this.apartmentIdArr = apartmentIdArr;
                  // if (this.checkedApartment.length > 0) {
                  //   this.clickApartmentLabelId = this.checkedApartment[0];
                  // }
                }
                //返显this.userApartmentList
                this.selectSure()
                resolve(res);
              })

            } else {
              // let checkedApartment = [];
              // let apartmentIndeterminate = false;
              // let apartmentList = [];
              // let apartmentIdArr = [];
              // let checkedApartmentNum = 0;
              // // _this.isLoading = false;
              // res.forEach((item, index) => {
              //   let buildList = [];
              //   let buildIdArr = [];
              //   let checkedBuild = [];
              //   //不是全选  有半选
              //   apartmentList.push({
              //     id: item.id,
              //     name: item.name,
              //     buildList: null,
              //     buildIdArr: buildIdArr,
              //     checkedBuild: checkedBuild,
              //     checkIndeterminate: false
              //   });
              //   apartmentIdArr.push(item.id)
              // })
              // this.treeData.checkedApartment = checkedApartment;
              // this.treeData.apartmentIndeterminate = false;
              // this.treeData.apartmentList = apartmentList;
              // this.treeData.apartmentIdArr = apartmentIdArr;
              // this.treeData.checkApartmentAll = false;
              // this.checkApartmentAll = false;
              // this.apartmentIndeterminate = false;
              // this.checkedApartment = checkedApartment;
              // this.apartmentList = apartmentList;
              // this.apartmentIdArr = apartmentIdArr;
              // if (this.checkedApartment.length > 0) {
              //   this.clickApartmentLabelId = this.checkedApartment[0];
              // }
              // data.opt.list = arr.concat(res);
              resolve(res);
            }
          });
        });
      },
    }
  };
</script>
<style lang="scss">
  .select-cascader-advert {
    .cascader-confirm-box {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      background-color: #FFF;
      background-image: none;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      overflow: hidden;
      box-sizing: border-box;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /* 限制在一个块元素显示的文本的行数 */
      -webkit-box-orient: vertical;
      /* 垂直排列 */
      word-break: break-all;
    }

    /* 内容自动换行 */
    .cont {
      display: flex;
      justify-content: flex-start;

      .lt {
        width: 48%;
        border: 1px solid rgba(228, 228, 228, 1);

        .lt-top {
          background: rgba(242, 242, 242, 1);
          height: 50px;
          display: flex;
          padding-left: 33px;
          // justify-content: center;
          align-items: center;
          border-bottom: 1px solid rgba(228, 228, 228, 1);

          .txt {
            height: 30px;
            line-height: 1;
            width: 80%;
            background-color: #FFF;
            background-image: none;
            border-radius: 4px;
            border: 1px solid #DCDFE6;
            box-sizing: border-box;
            color: #606266;
            display: inline-block;
            font-size: inherit;
            height: 40px;
            line-height: 40px;
            outline: 0;
            padding: 0 15px;
          }
        }

        .lt-cent {
          height: 530px;
          // margin-left: 33px;
          margin-top: 10px;
          overflow-y: auto;

          .checkbox-txt {
            color: black;
          }

          .check-group-box {
            margin-left: 33px;
          }
        }
      }

      .center {
        width: 22%;
        border: 1px solid rgba(228, 228, 228, 1);
        border-left: none;
        // margin-left: 2%;

        .center-top {
          display: flex;
          // justify-content: center;
          padding-left: 12px;
          align-items: center;
          background: rgba(242, 242, 242, 1);
          height: 50px;
          border-bottom: 1px solid rgba(228, 228, 228, 1);
        }

        .center-cent {
          height: 530px;
          overflow-y: auto;
        }
      }

      .rt {
        width: 30%;
        border: 1px solid rgba(228, 228, 228, 1);
        border-left: none;

        .rt-top {
          display: flex;
          // justify-content: center;
          align-items: center;
          padding-left: 12px;
          background: rgba(242, 242, 242, 1);
          height: 50px;
          border-bottom: 1px solid rgba(228, 228, 228, 1);
        }

        .rt-cent {
          height: 530px;
          overflow-y: auto;
          overflow-x: auto;
        }
      }

      .check-box-left {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 5px;
        margin-top: 5px;
        margin-left: 22px;
      }

      .check-box {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 5px;
        margin-top: 5px;
      }

      .apartment-check-box {
        display: flex;
        height: 30px;
        align-items: center;
        cursor: pointer;
      }

      .check-box-line {
        display: flex;
        height: 42px;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid rgba(228, 228, 228, 1);
        padding-left: 12px;

        // span {
        //   margin-left: 8px;
        // }
      }

      .checkbox-label {
        cursor: pointer;
        padding-left: 8px;
      }
    }

    .bottom {
      width: 100%;
      text-align: center;
      margin-top: 15px;
    }

    .el-checkbox {
      display: block;
    }

    // .lazy-cascader-search {
    //   .empty {
    //     width: calc(100% - 24px);
    //     box-sizing: border-box;
    //     background-color: #fff;
    //     color: #999;
    //     text-align: center;
    //     position: absolute;
    //     z-index: 999;
    //     padding: 12px 0;
    //     margin-top: 12px;
    //     box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    //     &:before {
    //       content: "";
    //       position: absolute;
    //       top: -12px;
    //       left: 36px;
    //       width: 0;
    //       height: 0;
    //       border-left: 6px solid transparent;
    //       border-right: 6px solid transparent;
    //       border-top: 6px solid transparent;
    //       border-bottom: 6px solid #fff;
    //       filter: drop-shadow(0 -1px 2px rgba(0, 0, 0, 0.02));
    //     }
    //   }
    // }
    .lazy-cascader-search {
      width: 100%;
    }

    /deep/.el-autocomplete {
      width: 90%;
    }

    /deep/.el-checkbox__inner {
      width: 16px;
      height: 16px;
    }

    /deep/.el-checkbox__inner::after {
      height: 8px;
      left: 5px;
    }

    .el-checkbox__label {
      font-size: 15px;
      padding-left: 2px;
    }

    /deep/.el-autocomplete-suggestion li {
      font-size: 15px;
    }

    #apartmentBox .el-checkbox {
      line-height: 17px !important;
    }

  }

  @media only screen and (max-width: 500px) {

    //  /deep/.el-dialog {
    //     width: 90%;
    //   }
    .select-cascader-advert {
      .el-dialog {
        width: 90% !important;
      }

      /deep/.el-dialog__body {
        padding: 5px 5px;
      }

      .cont .lt .lt-cent {
        margin-left: 5px;
      }

      .cont .center {
        width: 25%;
      }

      .cont .check-box-line {
        padding-left: 4px !important;
      }

      .cont .rt .rt-top {
        padding-left: 5px !important;
      }

      .cont .center .center-top {
        padding-left: 5px !important;
      }

      // /deep/.el-checkbox__label {
      //   padding-left: 2px;
      // }

      .cont .check-box-line span {
        padding-left: 2px;
      }

      .cont .lt .lt-top {
        padding-left: 5px;
      }

      .cont .checkbox-label {
        padding-left: 1px;
      }

      .cont .check-box-left {
        margin-left: 5px;
      }

      .cont .lt .lt-cent .check-group-box {
        margin-left: 10px;
      }
    }

    .el-autocomplete-suggestion__wrap {
      padding-bottom: 20px;
    }
  }
</style>