import CryptoJS from 'crypto-js';
 
export default {
    //随机生成指定数量的16进制key
    generatekey(num) {
        let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let key = "";
        for (var i = 0; i < num; i++) {
            let randomPoz = Math.floor(Math.random() * library.length);
            key += library.substring(randomPoz, randomPoz + 1);
        }
        return key;
    },
    
    //加密
    encrypt(word) {
       //十六进制数作为密钥
     let key = CryptoJS.enc.Utf8.parse("AD42F6697B035B7580E4FEF93BE20BAD");  
     //十六进制数作为密钥偏移量
     let iv = CryptoJS.enc.Utf8.parse('AD42F6697B035B7580E4FEF93BE20BAD');
     let srcs = CryptoJS.enc.Utf8.parse(word);
     let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
     return encrypted.toString();
    },
    //解密
    decrypt(word) {
         //十六进制数作为密钥
      let key = CryptoJS.enc.Utf8.parse("AD42F6697B035B7580E4FEF93BE20BAD");  
     //十六进制数作为密钥偏移量
     let iv = CryptoJS.enc.Utf8.parse('AD42F6697B035B7580E4FEF93BE20BAD');
    let decrypt = CryptoJS.AES.decrypt(word, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
    }
 
}