<!--
 * @Description: 授权货柜弹窗组件
 * @Author: YunShuTeam
 * @Date: 2022-07-15 11:16:12
-->
<template>
  <div class="select-cascader">
    <div class="cascader-confirm-box" @click="dialogShow">{{showText}}</div>
    <el-dialog title="授权货柜" :visible.sync="dialogVisible" :close-on-click-modal="false" @close="onCancel">
      <div class="choose-city">
        <div style="width:70px">选择城市</div>
        <el-cascader :options="provinceList" v-model="city" @change="oncilckCity" clearable></el-cascader>
      </div>
      <div class="cont">
        <div class="lt">
          <div class="lt-top">
            <div class="lazy-cascader-search">
              <el-autocomplete v-model="keyword" :debounce=0 :fetch-suggestions="querySearch" @select="handleSelect" placeholder="根据门店名称、门店负责人、负责人电话搜索" :trigger-on-focus="false" :clearable="true">
              </el-autocomplete>
            </div>
          </div>
          <div class="lt-cent" id="storesBox">
            <div class="stores-c">
              <!-- <div class="select-all item">
                <img :src="getCheckBoxImg(isCheckStoreAll)" class="img" @click="clickCheckStoreAll" />
                <span class="txt">全选</span>
              </div> -->
              <div class="stores-list" v-for="(item,index) in stores" :key="item.id">
                <div class="item">
                  <img :src="getCheckBoxImg(item.isAuth)" class="img" @click="clickStoreCheck(item,index)" />
                  <span class="txt" @click="clickStoreItem(item,index)" :style="{'color':clickStoreIndex == index ? '#30bac1':''}">{{getShopName(item.shopName)}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="center-top">
            <div class="select-all item">
              <img :src="getCheckBoxImg(isCheckSubAll)" class="img" @click="clickCheckSubAll" />
              <span class="txt">选货柜</span>
            </div>
          </div>
          <div class="center-cent" v-if="clickStoreIndex != -1">
            <div class="stores-list" v-for="(item,index) in stores[clickStoreIndex].counterList" :key="item.id">
              <div class="item borderBottom" @click="clickStoreSubCheck(item,index)">
                <img :src="getCheckBoxImg(item.isAuth)" class="img" />
                <span class="txt">{{item.counterName}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-button @click="onCancel">取消</el-button>
        <el-button @click="onSure">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    props: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  data() {
    return {
      //缓存
      cacheStores: '[]',
      stores: [],
      storesName: '',
      dialogVisible: false,
      showText: '',
      // isCheckStoreAll: 0,
      isCheckSubAll: 0,
      clickStoreIndex: -1,
      keyword: '',
      authCounterList: [],
      provinceList: [],
      city: '',
      city2: '',
      areaCode: '',
      code: '',
      groupCode: '',
    }
  },
  created() {
    this.queryStores();
    this.getCityData();
  },
  methods: {
    getShopName(val) {
      let limit = 30
      if (val.length <= limit) {
        return val
      } else {
        let sub = val.substring(0, limit)
        return `${sub}...`
      }
    },
    getCheckBoxImg(val) {
      return require(val == 0 ? '../../assets/ic_unselect.png'
        : val == 1 ? '../../assets/ic_select.png' : '../../assets/ic_select_half.png')
    },
    dialogShow() {
      this.dialogVisible = true
      this.refreshIsCheckStoreAll()
    },
    onCancel() {
      this.dialogVisible = false
      this.stores = JSON.parse(this.cacheStores)
      this.clickStoreIndex = -1
      this.isCheckSubAll = 0
      this.keyword = ''
      this.city = this.city2
    },
    onSure() {
      this.setSelectData()
      this.saveCacheStores()
      this.dialogVisible = false
      this.city2 = this.city
    },

    /****************************
    * 初始化 start 
    */
    async queryStores() {
      try {
        await this.initStores(await this.props.queryStores(''))
        this.clickStoreItem(this.stores[0], 0)
        this.setSelectData()
        this.saveCacheStores()
      } catch (error) { }
    },

    async initStores(storesData) {
      if (storesData.isAll == 'all') {
        try {
          storesData = await this.props.queryAllStores()
        } catch (error) {
          storesData.authCounterList = []
        }
        this.stores = storesData.authCounterList.map(item => {
          if (item.counterList.length > 0) {
            item.counterList.forEach((cItem, cIndex) => {
              cItem.isAuth = 1
            })
          }
          item.isAuth = 1
          return item
        })
      } else {
        this.stores = storesData.authCounterList.map(item => {
          if (item.counterList.length > 0) {
            let authCount = 0
            item.counterList.forEach((cItem, cIndex) => {
              if (cItem.isAuth == 1) {
                authCount++
              }
            })
            if (authCount == 0) {
              item.isAuth = 0
            } else if (authCount == item.counterList.length) {
              item.isAuth = 1
            } else {
              item.isAuth = 2
            }
          } else {
            item.isAuth = 0
          }
          return item
        })
      }
      this.refreshIsCheckStoreAll()
    },

    saveCacheStores() {
      new Promise((resolve, reject) => {
        this.cacheStores = JSON.stringify(this.stores)
      })
    },

    /*************************初始化 end */

    /******************************
     * 门店列表 start
     */
    //点击全选门店
    // clickCheckStoreAll() {
    //   this.isCheckStoreAll = this.isCheckStoreAll == 0 || this.isCheckStoreAll == 2 ? 1 : 0
    //   this.refreshList()
    //   if (this.clickStoreIndex != -1) {
    //     this.isCheckSubAll = this.isCheckStoreAll
    //     this.refreshSubList()
    //   }
    // },

    //点击门店Item
    async clickStoreItem(item, index) {
      this.clickStoreIndex = index
      this.isCheckSubAll = item.isAuth
      this.refreshSubList()
    },

    //点击门店Item选择框
    clickStoreCheck(item, index) {
      item.isAuth = item.isAuth == 0 || item.isAuth == 2 ? 1 : 0
      this.refreshIsCheckStoreAll()
      this.clickStoreItem(item, index)
    },

    // refreshList() {
    //   new Promise((resolve, reject) => {
    //     this.stores.forEach((item, index) => {
    //       item.isAuth = this.isCheckStoreAll == 1 ? 1 : 0
    //       item.counterList.forEach((couterItem, couterIndex) => {
    //         couterItem.isAuth = this.isCheckStoreAll == 1 ? 1 : 0
    //       })
    //     })
    //   })
    // },

    refreshIsCheckStoreAll() {
      new Promise((resolve, reject) => {
        let authCount = 0
        this.stores.forEach((item, index) => {
          if (item.isAuth == 1) {
            authCount++
          }
        })
        // if (authCount == 0) {
        //   this.isCheckStoreAll = 0
        // } else if (authCount == this.stores.length) {
        //   this.isCheckStoreAll = 1
        // } else {
        //   this.isCheckStoreAll = 2
        // }
      })
    },
    /*********************** 门店列表 end*/

    /******************************
     * 货柜列表 start
     */
    //点击全选货柜
    clickCheckSubAll() {
      if (this.clickStoreIndex == -1) return
      if (!this.stores[this.clickStoreIndex].counterList) return

      this.isCheckSubAll = this.isCheckSubAll == 0 || this.isCheckSubAll == 2 ? 1 : 0
      this.refreshSubList()
    },

    //点击选择货柜Item
    clickStoreSubCheck(item, index) {
      this.stores[this.clickStoreIndex].counterList[index].isAuth = item.isAuth == 0 || item.isAuth == 2 ? 1 : 0
      this.$set(this.stores, this.clickStoreIndex, this.stores[this.clickStoreIndex])
      this.refreshIsCheckSubAll()
    },

    refreshSubList() {
      new Promise((resolve, reject) => {
        this.stores[this.clickStoreIndex].isAuth = this.isCheckSubAll
        this.stores[this.clickStoreIndex].counterList.forEach((item, index) => {
          item.isAuth = this.isCheckSubAll == 1 ? 1 : (this.isCheckSubAll == 2 ? item.isAuth : 0)
        })
      })
    },

    refreshIsCheckSubAll() {
      new Promise((resolve, reject) => {
        let authCount = 0
        this.stores[this.clickStoreIndex].counterList.forEach((item, index) => {
          if (item.isAuth == 1) {
            authCount++
          }
        })
        if (authCount == 0) {
          this.isCheckSubAll = 0
        } else if (authCount == this.stores[this.clickStoreIndex].counterList.length) {
          this.isCheckSubAll = 1
        } else {
          this.isCheckSubAll = 2
        }
        this.stores[this.clickStoreIndex].isAuth = this.isCheckSubAll
      })
    },
    /******************************货柜列表 end */

    //搜索
    querySearch(query, callback) {
      let _this = this;
      _this.keyword = query;
      this.$nextTick(async () => {
        let res = await _this.props.queryStores(query,this.city)
        callback(res.authCounterList.map(item => {
          item.value = item.shopName
          return item
        }))
      })
    },

    handleSelect(item) {
      let locateIndex = -1
      this.stores.forEach((storeItem, storeIndex) => {
        if (item.shopId == storeItem.shopId) {
          locateIndex = storeIndex
        }
      })
      if (locateIndex != -1) {
        this.clickStoreItem(this.stores[locateIndex], locateIndex)
        document.getElementById('storesBox').scrollTop = 30 * locateIndex
        this.keyword = ''
      }
    },

    setSelectData() {
      // if (this.isCheckStoreAll == 1) {
      //   this.showText = '全选'
      //   this.$emit("input", ['0']);
      // } else {
        let selectCountList = []
        let selectTxt = ''
        this.stores.forEach((storeItem, storeIndex) => {
          let counterList = storeItem.counterList
          if (counterList && counterList.length > 0) {
            counterList.forEach((item, index) => {
              if (item.isAuth == 1) {
                if (selectTxt) {
                  selectTxt = `${selectTxt}、`
                }
                selectTxt = `${selectTxt}${item.counterName}`
                selectCountList.push({
                  shopId: item.shopId,
                  counterId: item.counterId
                })
              }
            })
          }
        })
        this.showText = selectTxt
        this.$emit("input", selectCountList)
      // }
    },

    getCityData() {
        this.get('/landlord-service/base/getTree').then(res => {
            this.provinceList = res;
        })
    },

    oncilckCity() {
      this.keyword = ''
      this.$nextTick(async () => {
        let res = await this.props.queryStores('',this.city)
        this.clickStoreIndex = -1
        this.isCheckSubAll = 0
        this.initStores(res)
      })
    }
  }
}
</script>

<style lang="scss" scoped src="./css/select-cascader-stores.scss">
</style>