export default {
  install: function (Vue, router) {

    //公用返回方法
    Vue.prototype.$back = function () {
      router.go(-1)
    }

    //时间格式化显示
    Vue.prototype.format = function (time, type) {
      if (type) {
        let ret = '';
        switch (type) {
          case 1:
            ret = moment(time).format("YYYY-MM-DD");
            break;
          default:
            ret = moment(time).format("YYYY-MM-DD hh:mm:ss");
            break;
        }
        return ret;
      } else {
        return moment(time).format("YYYY-MM-DD HH:mm:ss");
      }
    }

    let telCatalog = {};

    //调用机制
    Vue.prototype.$tel = {
      call(mark, cb) {
        if (mark && typeof mark == 'string' && telCatalog.hasOwnProperty(mark) && typeof cb == 'function') {
          telCatalog[mark](cb);
        }
      },
      wait(mark, cb) {
        if (mark && typeof mark == 'string' && typeof cb == 'function') {
          telCatalog[mark] = cb;
        }
      }
    }

    let map = {};

    //伪事件调用
    Vue.prototype.$evt = {
      on(name, cb) {
        if (name && typeof name == 'string' && typeof cb == 'function') {
          map[name] = cb;
        }
      },
      fire(name, data) {
        if (name && typeof name == 'string' && map.hasOwnProperty(name)) {
          map[mark](data);
        }
      },
      listen(name, cb) {
        if (name && typeof name == 'string' && typeof cb == 'function') {
          if (!map[name]) {
            map[name] = [cb];
          } else {
            map[name].push(cb);
          }
        }
      },
      dispatch(name, data) {
        if (name && typeof name == 'string' && map.hasOwnProperty(name)) {
          for (let i = 0; i < map[name].length; i++) {
            map[name][i](data);
          }
        }
      }
    }

    //常用正则表达式
    Vue.prototype.$pattern = {
      Az09: {
        val: /[^A-z0-9]/g,
        label: '只能包含大小写字母与数字'
      },
      zhAz09: {
        val: /[^\u4E00-\u9FA5A-z0-9]/g,
        label: '只能包含大小写字母与数字、中文'
      },
      _09: {
        val: /[^0-9]/g,
        label: '只能包含数字'
      },
      point09: {
        val: /[^0-9|.]/g,
        label: '只能包含数字与小数点'
      },
      Az09es: {
        val: /[^A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·]/g,
        label: '只能包含大小写字母、数字、英文符号'
      },
      account: {
        val: /^[A-z0-9]{6,16}$/,
        label: '只能包含大小写字母与数字,长度6-16位'
      },
      passWord: {
        val: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/,
        label: '最少包含字母、数字、1个大写字母、1个小写字母,长度8-16位'
      },
    }
    //校验身份证号
    Vue.prototype.checkIdCard = function (id) { // true "验证通过", false //校验不通过

      var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/; //号码规则校验

      if (!format.test(id)) {
        return false
      } //区位码校验
      //出生年月日校验   前正则限制起始年份为1900;

      var year = id.substr(6, 4), //身份证年
        month = id.substr(10, 2), //身份证月
        date = id.substr(12, 2), //身份证日
        time = Date.parse(month + '-' + date + '-' + year), //身份证日期时间戳date
        now_time = Date.parse(new Date()), //当前时间戳
        dates = (new Date(year, month, 0)).getDate(); //身份证当月天数

      if (time > now_time || date > dates) {
        return false
      } //校验码判断

      var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //系数

      var b = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'); //校验码对照表

      var id_array = id.split("");
      var sum = 0;
      for (var k = 0; k < 17; k++) {
        sum += parseInt(id_array[k]) * parseInt(c[k]);
      }
      if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
        return false
      }
      return true
    }

    //列转树方法
    Vue.prototype.listToTree = function (list, opt) {
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if (item.parentId) {
          for (let j = 0; j < list.length; j++) {
            let it = list[j];
            if (it.id == item.parentId) {
              if (it.children) {
                it.children.push(item)
              } else {
                it.children = [item];
              }
              break;
            }
          }
        }
      }
      let topList = [];
      for (let i = 0; i < list.length; i++) {
        let item = list[i];
        if (!item.parentId) {
          topList.push(item);
        }
      }
      return topList;
    }
    //字符串数字的对象数组排序
    Vue.prototype.objArrCompare = function (property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1.localeCompare(value2);
      }
    }
    //数组排序
    Vue.prototype.arrCompare = function (property) {
      return function (a, b) {
        return a - b;
      }
    }
    //锁类型  WF-X1C 21
    Vue.prototype.lockTypeArr = ["", "WF-S8", "", "WF-S91", "WF-S81", "WF-W1", "WF-X1", "WF-W5", "WF-U2", "", "WF-S20", "", "WF-S89", "WF-S50", "WF-S62", "WF-S40", "WF-S51", "WF-S63", "WF-S32", "WF-U3", "WF-W5T", "WF-X1C", "WF-L26", "WF-G1", "WF-S52", "WF-S21", "WF-G1K", "", "", "", "WF-X1M", "", "WF-W51", "", "WF-S70"]
    //用户来源数组
    Vue.prototype.userFromArr = ['', 'PMS排房', '云数排房', '添加同住人', '授权开门', '分享钥匙', 'App注册', '小程序注册', '排柜']
    //用户来源option
    Vue.prototype.userFromOption = [{
        label: '全部',
        value: ''
      },
      {
        label: 'PMS排房',
        value: '1'
      },
      {
        label: '云数排房',
        value: '2'
      },
      {
        label: '添加同住人',
        value: '3'
      },
      {
        label: '授权开门',
        value: '4'
      },
      {
        label: '分享钥匙',
        value: '5'
      },
      {
        label: 'App注册',
        value: '6'
      },
      {
        label: '小程序注册',
        value: '7'
      },
      {
        label: '排柜',
        value: '8'
      },
    ]
    //房客用户类型
    Vue.prototype.tenantUserArr = ['', '游客', '普通用户', '实名用户']
    //房客用户类型option
    Vue.prototype.tenantUserOption = [{
        label: '全部',
        value: ''
      },
      //   {
      //     label: '游客',
      //     value: '1'
      //   },
      {
        label: '普通用户',
        value: '2'
      },
      {
        label: '实名用户',
        value: '3'
      },
    ]
    //证件类型option
    Vue.prototype.certTypeOptions = [{
        value: "1",
        label: "身份证",
        category: 'id_card',
      },
      {
        value: "2",
        label: "港澳通行证",
        category: 'hong_kong_pass_card',
      },
      {
        value: "3",
        label: "驾驶证",
        category: 'driver_license',
      },
      {
        value: "4",
        label: "军官证",
        category: 'police',
      },
      {
        value: "5",
        label: "护照",
        category: 'passport',
      },
      {
        value: "6",
        label: "台湾身份证",
        category: 'taiwan_id_card',
      },
      {
        value: "7",
        label: "学生证/职工证",
        category: 'student',
      },
    ]

    //证件类型arr

    Vue.prototype.certTypeArr = ['--', '身份证', '港澳通行证', '驾驶证', '军官证', '护照', '台湾身份证', '学生证/职工证']
    Vue.prototype.certTypeString = ['--', 'id_card', 'hong_kong_pass_card', 'driver_license', 'police', 'passport', 'taiwan_id_card', 'student']
    Vue.prototype.cardStrToNum = function (category) {
      if (category.indexOf('student') == 0) {
        category = 'student';
      }
      return {
        'id_card': '1',
        'hong_kong_pass_card': '2',
        'driver_license': '3',
        'police': '4',
        'passport': '5',
        'taiwan_id_card': '6',
        'student': '7',
      } [category]
    }
    //账号状态arr
    Vue.prototype.accountStatusArr = ['', '未激活', '已激活', '失效', '删除']
    //账号状态Option
    Vue.prototype.accountStatusOptions = [{
        value: "1",
        label: "未激活"
      },
      {
        value: "2",
        label: "已激活"
      },
      {
        value: "3",
        label: "失效"
      },
      {
        value: "4",
        label: "删除"
      },

    ]
    //0待支付。长租，短租30分钟内，还没有支付的租约
    //1已取消，30分钟内主动取消支付，或30分钟后没有支付订单自动取消。
    //2待入住。短租自订或排房，租约入住日期时间还未开始的租约
    //3取消预订，短租预订了未来的入住订单，入住前取消了首期订单。
    //4在租。当前时间在租约租期内的，并且未发起退房的。长短租、自订、排房均有在租状态；
    //5退租中，房客发起了退房，退租状态为1：申请退房 2：房东同意退房 3： 确认退房的情况
    //6已退租，退房流程完成后，且当前时间超过退房日期时间的情况。
    //7强制解约  ，  自订的房间，在租期内，强制解约了。
    //租约状态option
    Vue.prototype.leaseStatusOptions = [{
        value: "0",
        label: "待支付",
      },
      {
        value: "1",
        label: "已取消",
      },
      {
        value: "2",
        label: "待入住",
      },
      {
        value: "3",
        label: "取消预订",
      },
      {
        value: "4",
        label: "在租",
      },
      {
        value: "5",
        label: "退租中",
      },
      {
        value: "6",
        label: "已退租",
      },
      {
        value: "7",
        label: "强制解约",
      },
    ]
    ////租约状态Str
    Vue.prototype.leaseStatusStr = ['待支付', '已取消', '待入住', '取消预订', '在租', '退租中', '已退租', '强制解约']
    Vue.prototype.getMenuList = [{
        name: "房源列表",
        menuId: 10,
        path: "/main/apartment/list"
      },
      {
        name: "房型列表",
        menuId: 11,
        path: "/main/house/list"
      },
      {
        name: "房间列表",
        menuId: 12,
        path: "/main/room/list"
      },
      {
        name: "门禁列表",
        menuId: 66,
        path: "/main/AccessControl/list"
      },
      {
        name: "人证机列表",
        menuId: 13,
        path: "/main/auth-device/list"
      },
      {
        name: "绑定记录",
        menuId: 14,
        path: "/main/lock/list"
      },
      {
        name: "分离式门禁列表",
        menuId: 68,
        path: "/main/separateAccessControl/list"
      },
      {
        name: "蓝牙公钥列表",
        menuId: 69,
        path: "/main/BluetoothKey/list"
      },
      {
        name: "电子门禁列表",
        menuId: 76,
        path: "/main/electronicAccessControl/list"
      },
      {
        name: "智能电表列表",
        menuId: 70,
        path: "/main/smartMeter/list"
      },
      {
        name: "智能水表列表",
        menuId: 71,
        path: "/main/smartWaterMeter/list"
      },
      {
        name: "认证记录",
        menuId: 20,
        path: "/main/authentication/list"
      },
      {
        name: "智能设备",
        menuId: 62,
        path: "/main/ied/list"
      },
      {
        name: "通行记录",
        menuId: 67,
        path: "/main/PassRecord/list"
      },
      {
        name: "订单列表",
        menuId: 15,
        path: "/main/order/list"
      },
      {
        name: "退款列表",
        menuId: 64,
        path: "/main/Refundlist/list"
      },
      {
        name: "财务概览",
        menuId: 16,
        path: "/main/finance/overview"
      },
      {
        name: "销售统计",
        menuId: 17,
        path: "/main/sale/list"
      },
      {
        name: "对账管理",
        menuId: 18,
        path: "/main/statement/list"
      },
      {
        name: "提款记录",
        menuId: 58,
        path: "/main/record/list"
      },
      {
        name: "转账记录",
        menuId: 59,
        path: "/main/money/list"
      },
      {
        name: '商户列表',
        menuId: 89,
        path: '/main/landlord/list'
      },
      {
        name: "房客列表",
        menuId: 19,
        path: "/main/tenant/list"
      },
      {
        name: "退房列表",
        menuId: 65,
        path: "/main/CheckOut/list"
      },
      {
        name: "租约列表",
        menuId: 105,
        path: "/main/leaseManagement/list"
      },
      {
        name: "约看列表",
        menuId: 51,
        path: "/main/date/list"
      },
      {
        name: "房管员列表",
        menuId: 26,
        path: "/main/account/list"
      },
      {
        name: "权限管理",
        menuId: 27,
        path: "/main/jurisdiction/list"
      },
      {
        name: "合同列表",
        menuId: 53,
        path: "/main/contract/list"
      },
      {
        name: "合同模板",
        menuId: 75,
        path: "/main/ContractTemplate/list"
      },
      {
        name: "货管员列表",
        menuId: 81,
        path: "/main/cargoController/list"
      }, {
        name: "门店管理",
        menuId: 106,
        path: "/main/groupManagement/list"
      },
      {
        name: "货柜管理",
        menuId: 82,
        path: "/main/containerManagement/list"
      },
      {
        name: "品类管理",
        menuId: 83,
        path: "/main/categoryManagement/list"
      },
      {
        name: "商品管理",
        menuId: 84,
        path: "/main/commodityManagement/list"
      },
      {
        name: "售货订单",
        menuId: 85,
        path: "/main/salesOrder/list"
      },
      {
        name: "开票记录",
        menuId: 107,
        path: "/main/billingRecord/list"
      },
      {
        name: "意见反馈",
        menuId: 88,
        path: "/main/containerFeedback/list"
      },
      {
        name: "系统用户",
        menuId: 21,
        path: "/main/user/list"
      },
      {
        name: "导出中心",
        menuId: 171,
        path: "/main/exportCenter/list"
      },
      {
        name: "参数设置",
        menuId: 101,
        path: "/main/parameter/list"
      },
      {
        name: "广告管理",
        menuId: 102,
        path: "/main/adManagement/list"
      },
      {
        name: "广告位管理",
        menuId: 103,
        path: "/main/adSpaceManagement/list"
      },
      {
        name: "角色管理",
        menuId: 22,
        path: "/main/role/list"
      },
      {
        name: "菜单管理",
        menuId: 23,
        path: "/main/menu/list"
      },
      {
        name: "房东信息",
        menuId: 61,
        path: "/main/information/detail/show/0"
      },
      {
        menuId: 8,
        name: "登录日志",
        path: "/main/log/list"
      },
      {
        menuId: 108,
        name: "操作日志",
        path: "/main/operationLog/list"
      },
      {
        menuId: 63,
        name: "意见反馈",
        path: "/main/feedback/list"
      }
    ]
    Vue.prototype.getMenuName = function (path) {
      this.getMenuList.forEach(item => {
        if (item.path == path) {
          this.cache.setLS('currentMenuName', item.name)
          console.log('当前菜单名称:', item.name)
        }
      });
    }
    Vue.prototype.operateLogAdd = function (operateName, dto, url, state) {
      let currentMenuName = this.cache.getLS('currentMenuName');
      console.log('currentMenuName:', currentMenuName)
      let operateLogDto = {
        operateName: currentMenuName + '-' + operateName,
        operateDetail: JSON.stringify(dto),
        path: url,
        moduleName: currentMenuName,
        state: state //1-成功 2-失败
      }
      this.post('user-service/operateLog/add', operateLogDto, {
        isUseResponse: true,
        isNotShowError: true,
        isNotShowCatchError: true,
      }).then(res1 => {
        console.log('res1:', res1)
      })
    }

    Vue.prototype.getCategory = function () {
      let dto = {
        pageNum: 1,
        pageSize: 9999
      };
      return new Promise((resolve, reject) => {
        this.post("mall-service/category/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          resolve(res.data.list)
        })
      })
    }

    Vue.prototype.getApartment = function () {
      return new Promise((resolve, reject) => {
        let dto = {
          pageNumber: "1",
          pageSize: "99999",
          landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ? null : this.cache.getLS("userInfo")["userId"],
          isQuery: true,
        };
        this.post("landlord-service/apartment/queryPagesApartment", dto).then(res => {
          res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
          resolve(res);
        });
      })
    }

    Vue.prototype.getCategoryName = function (value, list) {
      if (list && list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          if (value == list[i].id) {
            return list[i].name;
          }
        }
      }
    }

    //获取登录类型
    Vue.prototype.getLoginType = function (value, list) {
      let userInfo = this.cache.getLS('userInfo');
      return userInfo.roleType == '10';
    }

    //供应商登录
    Vue.prototype.isSupplierLogin = function (value, list) {
      let userInfo = this.cache.getLS('userInfo');
      return userInfo.roleType == '12';
    }

    //经销商登录
    Vue.prototype.isDealerLogin = function (value, list) {
      let userInfo = this.cache.getLS('userInfo');
      return userInfo.roleType == '13';
    }

    //经销类的商家
    Vue.prototype.isSellOnBusiness = function (value, list) {
      let userInfo = this.cache.getLS('userInfo');
      return userInfo.merchantType == '3';
    }

    // 有排房/退房操作权限的房管员
    Vue.prototype.getHousekeeperPlanAndQuitFlage = function (value, list) {
      let userInfo = this.cache.getLS('userInfo');
      if (userInfo.roleIds.indexOf('6') != -1 && userInfo.permitCodes.indexOf('plan_quit') != -1) {
        return true
      } else {
        return false
      }
    }

    // 房东身份
    Vue.prototype.getLandlordFlage = function (value, list) {
      let roleIds = this.cache.getLS('userInfo').roleIds ? this.cache.getLS('userInfo').roleIds : [];
      if (roleIds.indexOf('2') != -1) {
        return true
      } else {
        return false
      }
    }

    // 是否学校类型的房东 true:学校房东 false:普通房东
    Vue.prototype.getSchoolLandlordFlag = function () {
      let flag = this.cache.getLS('userInfo').category == 7 ? true : false;
      return flag
    }

    // 房源配置
    Vue.prototype.getHouseConfig = function (value) {
      return new Promise((resolve, reject) => {
        let dto = {};
        let myObj = {};
        this.post("/landlord-service/house/v1/scene/config/list", dto, {
          isUseResponse: true,
        }).then(res => {
          let list = []
          let houseConfig = []
          let houseTypeConfig = []
          let roomConfig = []
          let roomRentalConfig = []
          if (res.data.code == 0) {
            res.data.data.forEach(it => {
              // 学校类型的房源：it.houseType == 7
              if (it.houseType == 7) {
                list = it.houseSceneConfigRespList || []
                list.forEach(item => {
                  // 房源配置
                  if (item.configKey == 'house_config') {
                    houseConfig = item.config ? JSON.parse(item.config) : []
                  }
                  // 房型配置
                  if (item.configKey == 'roon_type_config') {
                    houseTypeConfig = item.config ? JSON.parse(item.config) : []
                  }
                  // 房间表单配置
                  if (item.configKey == 'roon_config') {
                    roomConfig = item.config ? JSON.parse(item.config) : []
                  }
                  // 租赁模式表单配置
                  if (item.configKey == 'rental_config') {
                    roomRentalConfig = item.config ? JSON.parse(item.config) : []
                  }

                })
              }
            })
          }
          myObj.houseConfig = houseConfig
          myObj.houseTypeConfig = houseTypeConfig
          myObj.roomConfig = roomConfig
          myObj.roomRentalConfig = roomRentalConfig
          resolve(myObj)
        });
      })
    }

    // 超级管理员身份
    Vue.prototype.getAdministratorFlage = function (value, list) {
      let roleIds = this.cache.getLS('userInfo').roleIds ? this.cache.getLS('userInfo').roleIds : [];
      if (roleIds.indexOf('1') != -1) {
        return true
      } else {
        return false
      }
    }

    // 获取版本号
    Vue.prototype.getVersion = function () {
      let version = process.env.VUE_APP_VERSION
      return version
    }

  }
}