var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-overview" }, [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))])
    ]),
    _c(
      "div",
      { staticClass: "table" },
      _vm._l(_vm.list, function(item) {
        return _c(
          "div",
          { key: item.label },
          [
            _c(
              "el-row",
              { staticClass: "col" },
              [
                _c("el-col", { staticClass: "label", attrs: { span: 12 } }, [
                  _vm._v(_vm._s(item.label))
                ]),
                item.on
                  ? _c(
                      "el-col",
                      { staticClass: "value", attrs: { span: 12 } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "link",
                            on: {
                              click: function($event) {
                                item.on(item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.value))]
                        )
                      ]
                    )
                  : _vm._e(),
                item.opt
                  ? _c(
                      "el-col",
                      { staticClass: "value", attrs: { span: 12 } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "link",
                            on: {
                              click: function($event) {
                                item.opt.on(item)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.opt.value))]
                        )
                      ]
                    )
                  : _c(
                      "el-col",
                      { staticClass: "value", attrs: { span: 12 } },
                      [_vm._v(_vm._s(item.value))]
                    )
              ],
              1
            ),
            item.isRow
              ? _c(
                  "el-row",
                  { staticClass: "col" },
                  [
                    _c("el-col", { staticClass: "label", attrs: { span: 12 } }),
                    _c("el-col", { staticClass: "value", attrs: { span: 12 } })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }