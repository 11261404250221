import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import AES from "./utils/aes.js"

//资源库
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../theme/index.css'//自定义主题颜色
import { Message } from 'element-ui'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.use(ElementUI)

Vue.config.interceptors = {
  response: res => {
    if (res.status === 200 && res.data.code == 0) {
      return true
    } else if (res.status === 200 && res.data.code == 1001) {
      Message({
        message: '登录已超时',
        type: 'warning'
      })
      router.push('/login')
      return false
    } else {
      return false
    }
  }
}

//脚本库
import ajax from './base_modules/plugin/ajax'
import cache from './base_modules/plugin/cache'
import common from './base_modules/plugin/common'
Vue.prototype.AES = AES 
Vue.config.productionTip = false
Vue.config.TOKEN_NAME = 'accessToken' //全局自定义token名

let url = {
  test: {
    BASE_URL: 'http://10.0.14.60:6060',
    UPLOAD_URL: 'http://10.0.14.60:6060'
  },
  pre: {
    BASE_URL: 'https://apartment-preapi.wfyundata.com',
    UPLOAD_URL: 'https://apartment-preapi.wfyundata.com'
  },
  pro: {
    BASE_URL: 'https://apartment-api.wavewisdom.com',
    UPLOAD_URL: 'https://apartment-api.wavewisdom.com'
  },
  dev: {
    BASE_URL: 'http://10.0.14.57:6060',
    UPLOAD_URL: 'http://10.0.14.57:6060'
  }
}
Vue.prototype.allUrl = url

Vue.config.urlOpt = [
  ['http://10.0.14.57:6060', 'http://10.0.14.57:6060'],
  ['http://10.0.14.60:6060', 'http://10.0.14.60:6060']
]
Vue.config.urlWebSocket = [
  ['http://10.0.14.57:6060', 'ws://10.0.14.56:8083/mqtt','admin','public'],
  ['http://10.0.14.60:6060', 'ws://10.0.14.61:8083/mqtt','admin','public'],
  ['https://apartment-preapi.wfyundata.com', 'wss://apartment-mqtt-pre.wfyundata.com:8184/mqtt','admin','public'],
  ['https://apartment-api.wavewisdom.com', 'wss://mqtt.wavewisdom.com:8184/mqtt','apartment-web','apartment@wfys##'],
]
// 测试环境
// Vue.config.BASE_URL = url.test.BASE_URL
// Vue.prototype.EXport = url.test.BASE_URL
// Vue.prototype.UPLOAD_URL = url.test.UPLOAD_URL

// 预发布环境
// Vue.config.BASE_URL = url.pre.BASE_URL;
// Vue.prototype.UPLOAD_URL = url.pre.UPLOAD_URL;

// 生产环境
// Vue.config.BASE_URL = url.pro.BASE_URL;
// Vue.prototype.UPLOAD_URL = url.pro.UPLOAD_URL;

// 开发环境
// Vue.config.BASE_URL = url.dev.BASE_URL;
// Vue.prototype.UPLOAD_URL = url.dev.UPLOAD_URL;

Vue.prototype.EXport = AJAX_URL
Vue.config.BASE_URL = AJAX_URL
Vue.prototype.UPLOAD_URL = AJAX_URL

Vue.use(ajax, router)
Vue.use(cache)
Vue.use(common, router)

//组件库
import appList from './base_modules/components/app-list'
import appHeader from './base_modules/components/app-header'
import appDetail from './base_modules/components/app-detail'
import appBatch from './base_modules/components/app-batch'
import appForm from './base_modules/components/app-form'
import appOverview from './base_modules/components/app-overview'
import appTree from './base_modules/components/app-tree'
import Verify from './base_modules/components/verifition/Verify'
import LazyCascader from './base_modules/components/LazyCascader'
import selectCascader from './base_modules/components/select-cascader'
import selectCascaderAdvert from './base_modules/components/select-cascader-advert'
import selectCascaderStores from './base_modules/components/select-cascader-stores'
import selectCascaderGateways from './base_modules/components/select-cascader-gateways'
import sentryControl from "./sentry/sentryControl";

Vue.component('app-list', appList)
Vue.component('app-header', appHeader)
Vue.component('app-detail', appDetail)
Vue.component('app-batch', appBatch)
Vue.component('app-form', appForm)
Vue.component('app-overview', appOverview)
Vue.component('app-tree', appTree)
Vue.component('Verify', Verify)
Vue.component('lazy-cascader', LazyCascader)
Vue.component('select-cascader', selectCascader)
Vue.component('select-cascader-advert', selectCascaderAdvert)
Vue.component('select-cascader-stores', selectCascaderStores)
Vue.component('select-cascader-gateways', selectCascaderGateways)

// 配置路由权限
router.beforeEach((to, from, next) => {
  localStorage.setItem('beforePath', from.path)
  if (
    localStorage.getItem('userInfo') ||
    to.path == '/login' ||
    to.path == '/downLoad'||
    to.path == '/secondCheck'||
    to.path == '/occupancy/1'||
    to.path == '/occupancy/2'||
    to.path == '/occupancy/3'
  ) {
    // 判断本地是否存在access_token
    next()
  } else {
    // 未登录,跳转到登陆页面，并且带上 将要去的地址，方便登陆后跳转。
    // if(to.path != '/'){//只输入域名就访问，to.path为'/',不提示登录已过期
    //     Message({
    //        message: '登录已过期',
    //        type: 'warning'
    //     })
    // }
    next('/login')
  }
})

sentryControl.init(Vue,router);

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
