var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-detail" },
    [
      _vm.title
        ? _c("app-header", { attrs: { title: _vm.title, isShowBack: true } })
        : _vm._e(),
      _vm.subTitle
        ? _c("div", { staticClass: "app-detail__subTitle" }, [
            _vm._v(_vm._s(_vm.subTitle))
          ])
        : _vm._e(),
      _c(
        "app-form",
        { attrs: { opt: _vm.opt, entry: _vm.entryName } },
        [
          _vm._l(_vm.slotArray, function(item) {
            return _c("template", { slot: item }, [_vm._t(item)], 2)
          })
        ],
        2
      ),
      _c(
        "footer",
        { staticClass: "action-but clearfix" },
        _vm._l(_vm.buttons, function(item) {
          return _c(
            "div",
            { key: item.name, staticClass: "action-but-item" },
            [
              item.show.indexOf(_vm.type) != -1
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: item.type ? item.type : "primary",
                        icon: item.icon
                      },
                      on: {
                        click: function($event) {
                          _vm.onAction(item.cb, item.isNotValidate)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }