<template>
  <div class="app-list">
    <slot name="top"></slot>

    <app-header :title="title" :isShowRefresh="isShowHeaderRefresh" :isShowBack="isShowBack" @refresh="onRefresh"
      v-if="title"></app-header>

    <slot name="header"></slot>

    <el-row v-if="searchInps.length != 0">
      <el-col :span="24">
        <el-form :inline="true" :model="searchForm" ref="listForm" :rules="rules">
          <el-form-item v-for="item in searchInps" :key="item.key" :label="item.label"
            :prop="item.rules? item.key : null">
            <el-input v-if="!item.type" v-model.trim="searchForm[item.key]" :disabled="item.disabled"
              :placeholder="'根据'+item.label+'搜索'" @keyup.enter.native="onSearch" clearable @clear="handleClear(item)"
              :style="{width:item.width}" :maxlength="item.maxlength?item.maxlength:100"></el-input>
            <el-input v-else-if="item.type=='number'" type="number" :disabled="item.disabled"
              v-model.trim="searchForm[item.key]" @keyup.enter.native="onSearch" :placeholder="'根据'+item.label+'搜索'"
              clearable></el-input>
            <el-select v-else-if="item.type=='selectCity'" filterable v-model="searchForm[item.key]"
              :disabled="item.disabled" :placeholder="'根据'+item.label+'搜索'" @change="onChangeSelect(item)"
              @clear="handleClear(item)" clearable>
              <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.label"></el-option>
            </el-select>
            <el-select v-else-if="item.type=='remoteSearchById'" filterable v-model="searchForm[item.key]"
              :disabled="item.disabled" :placeholder="'根据'+item.label+'搜索'" @change="onChangeSelect(item)"
              @clear="handleClear(item)" clearable>
              <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value"></el-option>
            </el-select>
            <el-select v-else-if="item.type=='select'" filterable v-model="searchForm[item.key]"
              :disabled="item.disabled" :placeholder="'根据'+item.label+'搜索'" @change="onChangeSelect(item)"
              @clear="handleClear(item)" clearable>
              <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value"></el-option>
            </el-select>
            <el-select v-else-if="item.type=='selectMust'" filterable v-model="searchForm[item.key]"
              :disabled="item.disabled" :placeholder="'根据'+item.label+'搜索'" @change="onChangeSelect(item)">
              <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value"></el-option>
            </el-select>
            <el-select v-else-if="item.type=='selectOpenBank'" filterable remote reserve-keyword 
             v-model="searchForm[item.key]" :disabled="item.disabled" :placeholder="'根据'+item.label+'搜索'" :remote-method="item.opt.remoteMethod"
             @change="onChangeSelect(item)" @clear="handleClear(item)" clearable>
              <el-option v-for="it in item.opt.list" :key="it.label" :label="it.label" :value="it.label"></el-option>
            </el-select>
            <!-- select不能输入，只能选择 -->
            <el-select v-else-if="item.type=='select-no-filterable'" v-model="searchForm[item.key]"
              :disabled="item.disabled" :placeholder="'根据'+item.label+'搜索'" @change="onChangeSelect(item)"
              @clear="handleClear(item)" clearable>
              <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value"></el-option>
            </el-select>
            <el-date-picker v-else-if="item.type=='data-range'" v-model="searchForm[item.key]" type="daterange"
              range-separator="至" :disabled="item.disabled" :value-format="item.timeType ? item.timeType : 'yyyy-MM-dd'"
              :format="item.format ? item.format : item.timeType ? item.timeType : 'yyyy-MM-dd'"
              start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            <el-date-picker @input="inputTime(searchForm[item.key])" v-else-if="item.type=='time-range'"
              v-model="searchForm[item.key]" type="datetimerange" range-separator="至" :disabled="item.disabled"
              :value-format="item.timeType ? item.timeType : 'yyyy-MM-dd HH:mm:ss'"
              :format="item.format ? item.format : item.timeType ? item.timeType : 'yyyy-MM-dd HH:mm:ss'"
              start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            <el-date-picker v-else-if="item.type=='daterange'" v-model="searchForm[item.key]" type="daterange"
              :disabled="item.disabled" :value-format="item.timeType ? item.timeType : 'yyyy-MM-dd HH:mm:ss'"
              :format="item.format ? item.format : item.timeType ? item.timeType : 'yyyy-MM-dd HH:mm:ss'"
              range-separator="至"></el-date-picker>
            <el-date-picker v-else-if="item.type=='datetime'" v-model="searchForm[item.key]" type="datetime"
              :disabled="item.disabled" :value-format="item.timeType ? item.timeType : 'yyyy-MM-dd HH:mm:ss'"
              :format="item.format ? item.format : item.timeType ? item.timeType : 'yyyy-MM-dd HH:mm:ss'">
            </el-date-picker>
            <el-date-picker v-else-if="item.type=='timer-month'" v-model="searchForm[item.key]" type="monthrange"
              range-separator="至" :value-format="item.timeType ? item.timeType : 'yyyy-MM-dd HH:mm:ss'"
              :format="item.timeType ? item.timeType : 'yyyy-MM-dd HH:mm:ss'" start-placeholder="开始月份"
              end-placeholder="结束月份"></el-date-picker>
            <el-date-picker v-else-if="item.type=='date'" v-model="searchForm[item.key]" :disabled="item.disabled"
              :value-format="item.timeType ? item.timeType : 'yyyy-MM-dd HH:mm:ss'"
              :format="item.timeType ? item.timeType : 'yyyy-MM-dd HH:mm:ss'" type="date"
              :placeholder="'根据'+item.label+'搜索'"></el-date-picker>
            <el-button-group v-else-if="item.type=='btnGroup'">
              <el-button v-for="it in item.buts" :key="it.label" :type="it.type" :disabled="item.disabled"
                @click="onSelect(it.timeType)">{{it.label}}</el-button>
            </el-button-group>
            <el-select v-else-if="item.type=='select-remote-filterable'" v-model="searchForm[item.key]"
              :multiple="item.opt.multiple==false?false:true" filterable
              :filter-method="handleSelectRemoteFilterable(item)" :placeholder="'请输入'+item.label+'关键词搜索'"
              :loading="item.opt.isLoading" clearable @change="handleSearchSelectRemoteFilterableChange(item)"
              @focus="handleSelectRemoteFilterableFocus(item)" @clear="handleClear(item)">
              <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.label"></el-option>
            </el-select>
            <!-- <el-select v-else-if="item.type=='select'" v-model="searchForm[item.key]"
            :placeholder="type!='show'?'请选择'+item.label:''" clearable>
            <el-option v-for="it in item.opt.list" :key="it.value" :label="it.label" :value="it.value"></el-option>
          </el-select> -->
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" round @click="onSearch">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button plain round @click="onSearchReset">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button class="onExport" v-for="(item,index) in exportButtons" :key="index" @click="onExport(item)">导出</el-button>
          </el-form-item>
          <el-button v-if="buttons.length == 0" class="refresh search-refresh" icon="el-icon-refresh" circle
            @click="onRefresh"></el-button>
          <div class="custom_button">
            <el-button class="custom_button-item" v-for="(items,indexs) in opt.custom" :key="indexs" @click="items.on()"
              size="medium">{{items.label}}</el-button>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <el-row class="buts-bar">
      <el-col :span="24"  v-if="buttons.length != 0">
        <el-button v-for="item in buttons" :key="item.id" @click="onButton(item)" size="medium"
          :icon="item.icon?item.icon:item.type!=undefined?'el-icon-'+['plus', 'edit', 'delete','circle-plus-outline'][item.type]:'question'"
          :type="item.color?item.color:item.type!=undefined?['primary', 'success', 'danger','primary'][item.type]:'primary'">
          {{item.name?item.name:item.type!=undefined?['添加',
          '编辑',
          '删除'][item.type]:'未命名'}}
        </el-button>
      </el-col>
      <el-col :span="24" style="text-align: right;">
          <!-- <el-button class="onExport" v-for="(item,index) in exportButtons" :key="index" @click="onExport(item)">导出
          </el-button> -->
        <el-button v-if="buttons.length != 0" class="refresh" icon="el-icon-refresh" circle @click="onRefresh"></el-button>
      </el-col>
    </el-row>

    <div class="table-box">
      <el-table class="table-body" ref="multipleTable" :data="list" :empty-text="emptyText"
        @selection-change="handleSelectionChange" @row-click="handleRowClick" @current-change="clickCurrentChange"
        v-loading="isLoading" :highlight-current-row="opt.isShowHighliht" :row-class-name="tableRowClassName"
        >
        <el-table-column v-if="!isNotShowSelection" type="selection" width="55"></el-table-column>

        <el-table-column v-for="(item, index) in columns" :key="item.id" :prop="item.key" :label="item.label"
          :width="item.width" :fixed="item.fixed" :align="item.align ? item.align: 'left'"
          :sortable="item.sortable ? item.sortable : false">
          <template slot-scope="scope">
            <a class="row-nav"
              v-if="item.on&&index==0&&item.type != 'img' && (item.isShowA?scope.row[item.isShowA]:true)"
              @click="item.on(scope.row)">{{scope.row[item.key]}}</a>
            <a class="row-disable"
              v-else-if="item.on&&index==0&&item.type != 'img' && (item.isShowA?!scope.row[item.isShowA]:false)">{{scope.row[item.key]}}</a>
            <a class="row-nav-vice" v-else-if="item.on&&index!=0&&item.type != 'img'"
              @click="item.on(scope.row)">{{scope.row[item.key]}}</a>
            <el-popover trigger="hover" placement="top" v-else-if="item.opt && item.opt.isUserPopover"
              style="white-space: nowrap;">
              <p>{{scope.row[item.key]}}</p>
              <div slot="reference" style="text-overflow: ellipsis;overflow: hidden;">{{scope.row[item.key]}}</div>
            </el-popover>
            <el-popover class="row-action-alt" :trigger="scope.row[item.key].label!=item.opt.ativeStatus ? 'manual' : 'click'" placement="top" v-else-if="item.opt && item.opt.isClickPopover"
              style="white-space: nowrap;">
              <p>{{scope.row[item.key].clickPopoverCont}}</p>
              <div slot="reference" class="active" v-if="scope.row[item.key].label!=item.opt.ativeStatus" style="text-overflow: ellipsis;overflow: hidden;">{{scope.row[item.key].label}}</div>
              <div slot="reference" class="inactive" v-if="scope.row[item.key].label==item.opt.ativeStatus" style="text-overflow: ellipsis;overflow: hidden;">{{scope.row[item.key].label}}</div>
            </el-popover>
            <div class="row-switch-box" v-else-if="item.type=='switch'">
              <div class="row-switch-but" @click="onSwitch(item, scope.row)"></div>
              <el-switch v-model="scope.row[item.key]"
                :active-text="item.opt && item.opt.activeText ? item.opt.activeText : '启用'"
                :inactive-text="item.opt && item.opt.inactiveText ? item.opt.inactiveText : '禁用'" active-color="#67C23A"
                inactive-color="#F56C6C"></el-switch>
            </div>
            <div class="row-switch-box" v-else-if="item.type=='switch1'">
              <div class="row-switch-but" @click="onSwitch1(item, scope.row,item.opt)"></div>
              <el-switch v-model="scope.row[item.key]"
                :active-text="item.opt && item.opt.activeText ? item.opt.activeText : '启用'"
                :inactive-text="item.opt && item.opt.inactiveText ? item.opt.inactiveText : '禁用'" active-color="#67C23A"
                inactive-color="#F56C6C"></el-switch>
            </div>
            <div class="row-switch-box" v-else-if="item.type=='switch2'">
              <div class="row-switch-but" @click="onSwitch(item, scope.row)"></div>
                <el-switch v-model="scope.row[item.key]" v-show="scope.row.isShow"
                :active-text="item.opt && item.opt.activeText ? item.opt.activeText : '启用'"
                :inactive-text="item.opt && item.opt.inactiveText ? item.opt.inactiveText : '禁用'" active-color="#67C23A"
                inactive-color="#F56C6C"></el-switch>
            </div>
            <div class="row-action-box" v-else-if="item.type=='action' && scope.row[item.key]">
              <!-- 隐藏显示的逻辑 -->
              <span class="row-action-but" v-for="(action, indexs) in item.opt.list" :key="indexs"
                @click="action.on(scope.row)" v-show="scope.row[item.key][indexs]">{{action.label}}</span>
              <!-- 显示的逻辑置灰 -->
              <!-- <span v-for="(action, indexs) in item.opt.list" :key="indexs">
                <span
                  class="row-action-but"
                  v-if="scope.row[item.key][indexs]==true"
                  @click="action.on(scope.row)"
                >{{action.label}}</span>
                <span
                  class="row-action-baclk"
                  v-else-if="scope.row[item.key][indexs]==false"
                >{{action.label}}</span>
              </span>-->
            </div>
            <div class="row-action-box" v-else-if="item.type=='action-back' && scope.row[item.key]">
              <span class="row-action-but" v-for="(action, indexs) in item.opt.list" :key="indexs"
                @click="actionBack(item, scope.row,action)" v-show="scope.row[item.key][indexs]">{{action.label}}</span>
            </div>
            <div class="row-action-box" v-else-if="item.type=='action-but'">
              <span class="row-action-but" v-for="(action, indexs) in item.opt.list" :key="indexs"
                @click="action.on(scope.row)">{{action.label}}</span>
            </div>
            <div class="row-action-box" v-else-if="item.type=='action-but-img'">
              <span v-for="(action, indexs) in item.opt.list" :key="indexs">
                <span class="row-action-but" v-if="scope.row[item.key][indexs]"
                  @click="action.on(scope.row)">{{action.label}}</span>
                <span class="row-action-baclk" v-else>无</span>
              </span>
            </div>
            <div class="row-img" v-else-if="item.type=='img'">
              <img v-if="scope.row[item.key]" :src="scope.row[item.key]" @click="item.on(scope.row)"
                class="table-row-img" />
            </div>
            <div class="row-img-shop" v-else-if="item.type=='img-shop'">
              <img v-if="scope.row[item.key]" :src="scope.row[item.key]" @click="item.on(scope.row)"
                class="table-row-img" />
              <span v-else>--</span>
            </div>
            <div class="row-list" v-else-if="item.type=='list'">
              <span v-for="(items,indexs) in scope.row[item.key]" :key="indexs" :style="items.style ? items.style: ''"
                @click="item.opt.on(items)">{{items.label}}</span>
            </div>
            <div class="row-list-group" v-else-if="item.type=='list-group'">
              <div v-for="(items,indexs) in scope.row[item.key]" :key="indexs" class="row-list-group_item">
                <div v-for="(itmess,indexss) in items" :key="indexss"
                  :style="items[indexss].style ? items[indexss].style: ''" @click="item.opt.on(items[indexss])">
                  {{items[indexss].label}}</div>
              </div>
            </div>
            <div class="row-action-alt" v-else-if="item.type=='actionStatus'">
              <span class="active" v-if="scope.row[item.key]!=item.opt.ativeStatus">{{scope.row[item.key]}}</span>
              <span class="inactive" v-if="scope.row[item.key]==item.opt.ativeStatus"
                @click="item.opt.on(scope.row, item)">{{scope.row[item.key]}}</span>
            </div>
            <div class="row-action-alt" v-else-if="item.type=='actionStatusList'">
              <span class="active" v-if="item.opt.ativeStatus.indexOf(scope.row[item.key]) == -1">{{scope.row[item.key]}}</span>
              <span class="inactive" v-if="item.opt.ativeStatus.indexOf(scope.row[item.key]) !== -1"
                @click="item.opt.on(scope.row, item)">{{scope.row[item.key]}}</span>
            </div>
            <div class="row-action-alt" v-else-if="item.type=='action-alt'">
              <span class="active"
                v-if="!!scope.row[item.key]">{{scope.row[item.key]==3?item.opt.activeText2:item.opt.activeText}}</span>
              <span class="inactive" v-if="!!!scope.row[item.key]"
                @click="item.opt.on(scope.row, item)">{{item.opt.inactiveText}}</span>
            </div>
            <div v-else-if="item.type=='diff-color'">
              <span
                :style="item.opt.style && scope.row[item.opt.key] ? item.opt.style: ''">{{scope.row[item.key]}}</span>
              <!-- <span>{{scope.row[item.key]}}</span> -->
            </div>
            <span v-else>{{scope.row[item.key]}}</span>
          </template>
        </el-table-column>
        <div slot="empty" style="height:200px;line-height:25px;" v-if="opt.isShowEmptyText">
          <img class="input__icon" src="../../assets/u21508.png" />
          <p>您的房源暂未绑定智能电表，平台支持<span style="color:#0099FF;cursor: pointer;" @click="opt.isShowEmptyText.on()">手动抄表</span>
          </p>
        </div>
      </el-table>
    </div>

    <el-pagination background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 50]"
      :page-size="limit" :total="total"></el-pagination>

    <el-dialog
      title="提示"
      :visible.sync="exportCenterVisible"
      width="30%"
    >
      <div style="padding-top: 20px;">导出任务已进入队列,可能需要花费一些时间,请稍后在导出中心查看!</div>
      <div v-if="total > 50000" style="color: #f7a934;font-size: 12px;padding-top: 10px;">温馨提示：单次导出上限为50000条数据</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportCenterVisible = false">关 闭</el-button>
        <el-button type="primary" @click="goToExportCenterList()">查看进度</el-button>
      </span>
    </el-dialog>

  
  </div>
</template>

<script>
  /**
   * 通用表格列表组件
   *
   * 派发回调事件get参数Object:
   * total:列表总数
   * data:列表数据
   *
   * 组件参数opt(Object)：
   * title:标题
   * columns:表头
   * buttons:表格操作
   * exportButtons: 表格右上角的操作按钮（导出按钮）
   * search:表格搜索栏
   * isNotShowSelection:是否不显示表格列表多选框，默认false
   * isShowBack:是否显示返回按钮，默认不显示
   *
   * 组件参数opt{表头参数columns(Array)
   * label:表头名称
   * key:字段名称
   * isShowA:若该字段无数据，则不高亮，且不能点击
   * width: 宽度(number)
   * on: 单击回调且高亮显示
   * type: 显示类型
   * opt: 针对不同类型的扩展配置
   *
   * 组件参数opt{表头参数columns{显示类型type(string)
   * switch:开关,默认单击延时限制3秒
   * opt{
   *  on: 单击回调
   *  isNotDelay: 是否不采用延时限制
   *  delayTime: 延时时间，默认3秒
   * }
   *
   */
  import axios from "axios";
  export default {
    props: {
      opt: {
        type: Object,
        default: function () {
          return {};
        }
      }
    },
    data() {
      return {
        rules: {},
        title: "",
        emptyText: "",
        columns: [],
        list: [],
        buttons: [],
        exportButtons: [],
        searchInps: [],
        searchForm: {},
        multipleSelection: [],
        currentPage: 1,
        skip: 1,
        limit: 10,
        total: 0,
        isShowHeaderRefresh: false,
        isShowBack: false,
        isLoading: false,
        isNotShowSelection: false, //是否显示表格列表多选框
        logListFlag: false, //是否是详情列表  path有/list不是详情列表
        apartmentList: [],
        exportCenterVisible: false,//导出任务成功弹框提示
      };
    },
    activated() {
      console.log("app-list activated!");
      this.logListFlag = this.$route.path.indexOf('/list') == -1;
      if (!this.logListFlag) //列表
      {
        let appListPath = this.cache.get("appListPath");
        if (appListPath && appListPath == this.$route.path) {
          this.get();
        } else {
          this.cache.set("appListPath", this.$route.path);
          this.cache.del("appListSearchFlag");
          this.onSearchReset();
        }
      } else //详情里的列表
      {
        let appListPath = this.cache.get("appLogListPath");
        let routePath = this.$route.path;
        let pathFlag = routePath.substring(0, routePath.indexOf("/log") + 10); ///log开始位置加上10位
        if (appListPath && appListPath == pathFlag) {
          this.get();
        } else {
          this.cache.set("appLogListPath", pathFlag);
          this.cache.del("appLogListSearchFlag");
          this.onSearchReset();
        }
      }
      if(this.$props.opt.getApartmentListflag) {
        this.getApartment().then(res =>{  
          this.apartmentList = res
        })
      }
      
      // 判断按钮权限
      // if (jurisdictionBtn && this.$props.opt.buttons) {
      //   this.buttons = jurisdictionBtn(this.$route, this.$props.opt.buttons);
      //   if (
      //     this.$props.opt.isNotShowSelection ||
      //     (this.$props.opt.isNotShowSelection == undefined &&
      //       (!this.$props.opt.buttons || this.$props.opt.buttons.length == 0))
      //   ) {
      //     this.isNotShowSelection = true;
      //   }
      // }
      // if (this.$refs['listForm']) {
      //   this.$refs['listForm'].resetFields();
      // }
    },
    mounted() {
      console.log("app-list mounted!", this.$props);
      let _this = this;
      this.title = this.$props.opt.title;
      this.emptyText = "";
      this.isShowHeaderRefresh = this.$props.opt.isShowHeaderRefresh;
      !this.$props.opt.buttons || this.$props.opt.buttons.length == 0;
      this.exportButtons = this.$props.opt.exportButtons ? this.$props.opt.exportButtons : [];
      if (this.$props.opt.isShowBack) {
        this.isShowBack = true;
      }
      if (this.$props.opt.buttons) {
        for (let i = 0; i < this.$props.opt.buttons.length; i++) {
          let item = this.$props.opt.buttons[i];
          item.id = Math.random().toString();
        }

        if (typeof jurisdictionBtn !== "undefined") {
          // 判断按钮权限
          this.buttons = jurisdictionBtn(this.$route, this.$props.opt.buttons);
        } else {
          this.buttons = this.$props.opt.buttons;
        }
      } else if (
        this.$props.opt.isNotShowSelection ||
        (this.$props.opt.isNotShowSelection == undefined &&
          (!this.$props.opt.buttons || this.$props.opt.buttons.length == 0))
      ) {
        this.isNotShowSelection = true;
      }
      if (this.$props.opt.search) {
        this.$props.opt.search.forEach(item => {
          if (item.opt && item.opt.parent) {
            item.disabled = true;
          }
        });
        this.searchInps = this.$props.opt.search;
      }

      if (
        this.$props.opt.isNotShowSelection ||
        (this.$props.opt.isNotShowSelection == undefined &&
          (!this.buttons || this.buttons.length == 0))
      ) {
        this.isNotShowSelection = true;
      }
      this.columns = this.$props.opt.columns;
      if (this.$props.opt.search) {
        this.$props.opt.search.forEach(item => {
          if (item.rules) {
            this.rules[item.key] = item.rules;
          }
        })
      }
    },
    methods: {
      //获取表格数据并填充
      get() {
        let _this = this;


        // 有bug提出需要保留输入框输入的空格，故注释了本块代码
        // for (var i in this.searchForm) {
        //   if (
        //     typeof this.searchForm[i] != "object" &&
        //     typeof this.searchForm[i] == "string"
        //   ) {
        //     this.searchForm[i] = this.searchForm[i].replace(/ /g, "");
        //   }
        // }
        let searchFlag = '';
        if (this.logListFlag) {
          searchFlag = this.cache.get("appLogListSearchFlag");
        } else {
          searchFlag = this.cache.get("appListSearchFlag");
        }
        let isNotSearch = !searchFlag || searchFlag != this.$route.path;
        if (isNotSearch) {
          _this.searchForm = {};
          _this.skip = 1;
          _this.currentPage = 1;
          _this.limit = 10;
          _this.total = 0;
          if (_this.$refs['listForm']) {
            _this.$refs['listForm'].resetFields();
          }
        }
        this.$emit("get", {
          searchForm: isNotSearch ? {} : this.searchForm,
          skip: this.skip.toString(),
          limit: this.limit.toString(),
          cb(data) {
            _this.isLoading = false;

            // 赋值搜索默认值
            if (_this.$props.opt.search) {
              _this.$props.opt.search.forEach(item => {
                if (item.value && !_this.searchForm[item.key]) {
                  _this.searchForm[item.key] = item.value;
                }
              });
            }
            _this.list = [];
            if (data && data.data == "") {
              data.data = [];
            }
            if (data && data.data) {
              if (data.total == 0) {
                _this.emptyText = "暂无数据";
              }
              _this.total = data.total;
              _this.currentPage = _this.skip;
              _this.list = data.data.length ? data.data : [];
              _this.title = _this.$props.opt.title;
            } else {
              _this.emptyText = "数据维护中";
            }
          }
        });

        if (typeof this.$props.opt.get == "function") {
          this.$props.opt.get({
            searchForm: isNotSearch ? {} : this.searchForm,
            skip: this.skip.toString(),
            limit: this.limit.toString(),
            cb(data) {
              _this.list = data.data.length ? data.data : [];
              _this.total = data.total ? data.total : data.data.length;
              _this.isLoading = false;
            }
          });
        }
      },
      onSearch() {

        if (this.$refs['listForm']) {
          this.$refs['listForm'].validate((valid) => {
            if (valid) {
              if (this.logListFlag) {
                this.cache.set("appLogListSearchFlag", this.$route.path);
              } else {
                this.cache.set("appListSearchFlag", this.$route.path);
              }

              if (this.$props.opt.beforeSearch) {
                if (
                  this.$props.opt.beforeSearch({
                    searchForm: this.searchForm,
                    skip: this.skip.toString(),
                    limit: this.limit.toString()
                  })
                ) {
                  this.onRefresh();
                }
              } else {
                this.onRefresh();
              }
            }
          })
        }

      },
      onSearchReset() {
        if (this.logListFlag) {
          this.cache.del("appLogListSearchFlag");
        } else {
          this.cache.del("appListSearchFlag");
        }
        if (this.$refs['listForm']) {
          this.$refs['listForm'].resetFields();
        }
        this.searchForm = {};
        this.onRefresh();
      },
      //刷新列表
      onRefresh() {
        this.list = [];
        let _this = this;
        setTimeout(() => {
          _this.isLoading = true;
          _this.skip = 1;
          _this.currentPage = 1;
          _this.limit = 10;
          _this.total = 0;
          _this.get();
        }, 0);
      },
      // 勾选单选框
      clickCurrentChange(val) {
        if (this.$props.opt.isShowHighliht) {
          this.multipleSelection = [val];
        }
      },
      //勾选列表复选框
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 点击了列表行
      handleRowClick(val) {
        if (typeof this.$props.opt.onRow == "function") {
          this.$props.opt.onRow(val);
        }
      },
      //改变表格展示条数
      handleSizeChange(val) {

        if (this.$refs['listForm']) {
          this.$refs['listForm'].validate((valid) => {
            if (valid) {
              this.skip = 1;
              this.currentPage = 1;
              this.limit = val;
              if (this.logListFlag) {
                this.cache.set("appLogListSearchFlag", this.$route.path);
              } else {
                this.cache.set("appListSearchFlag", this.$route.path);
              }
              this.get();
            }
          })
        } else {
          this.skip = 1;
          this.currentPage = 1;
          this.limit = val;
          if (this.logListFlag) {
            this.cache.set("appLogListSearchFlag", this.$route.path);
          } else {
            this.cache.set("appListSearchFlag", this.$route.path);
          }
          this.get();
        }

      },
      //表格翻页
      handleCurrentChange(val) {
        this.isLoading = true;
        if (this.$refs['listForm']) {
          this.$refs['listForm'].validate((valid) => {
            if (valid) {
              this.skip = val;
              if (this.logListFlag) {
                this.cache.set("appLogListSearchFlag", this.$route.path);
              } else {
                this.cache.set("appListSearchFlag", this.$route.path);
              }
              this.get();
            }
          })
        } else {
          this.skip = val;
          if (this.logListFlag) {
            this.cache.set("appLogListSearchFlag", this.$route.path);
          } else {
            this.cache.set("appListSearchFlag", this.$route.path);
          }
          this.get();
        }


      },
      //表格按钮
      onButton(item) {
        let _this = this;
        switch (item.type) {
          case 0:
            item.on();
            break;
          case 1:
            if (this.multipleSelection.length > 1) {
              this.$message({
                showClose: true,
                message: "此操作不支持多选",
                type: "warning"
              });
            } else if (this.multipleSelection.length == 0) {
              this.$message({
                showClose: true,
                message: "请选择一项",
                type: "warning"
              });
            } else {
              if (!this.multipleSelection[0]) {
                this.$message({
                  showClose: true,
                  message: "请选择一项",
                  type: "warning"
                });
                return;
              }
              if (item.confirm) {
                this.$confirm(item.confirm[0], "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                  })
                  .then(() => {
                    item.on({
                      id: this.multipleSelection[0].id,
                      data: this.multipleSelection[0],
                      refresh() {
                        _this.onRefresh();
                      }
                    });
                  })
                  .catch(action => {
                    console.log(action);
                  });
              } else {
                item.on({
                  id: this.multipleSelection[0].id,
                  data: this.multipleSelection[0],
                  refresh() {
                    _this.onRefresh();
                  }
                });
              }
            }
            break;
          case 2:
            if (this.multipleSelection.length > 0) {
              if (!this.multipleSelection[0]) {
                this.$message({
                  showClose: true,
                  message: "请选择一项",
                  type: "warning"
                });
                return;
              }
              var ids = [];
              for (let i = 0; i < this.multipleSelection.length; i++) {
                ids.push(this.multipleSelection[i].id);
              }
              item.on({
                ids: ids,
                list: this.multipleSelection,
                refresh() {
                  _this.onRefresh();
                }
              });
            } else {
              this.$message({
                showClose: true,
                message: "最少请选择一项",
                type: "warning"
              });
            }
            break;
          case 3:
            item.on();
            break;
          default:
            break;
        }
      },
      //表格操作
      actionBack(item, row, action) {
        let _this = this;
        if (action.on) {
          let lock = false;
          let ids = this.cache.getR("tableAction", []);
          for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            if (row.id == id) {
              lock = true;
              break;
            }
          }
          if (lock) {
            return this.$message({
              message: "操作频繁，请稍后再试",
              type: "warning"
            });
          } else {
            ids.push(row.id);
            this.cache.setR("tableAction", ids);
            let _delayTime =
              typeof item.opt.delayTime != "undefined" ?
              item.opt.delayTime :
              3000;

            setTimeout(() => {
              let ids = this.cache.getR("tableAction");
              ids.splice(ids.indexOf(row.id), 1);
              this.cache.setR("tableAction", ids);
            }, 3000);
            action.on(row, data => {
              if (data.result) {
                _this.$message({
                  showClose: true,
                  message: data.msg ? data.msg : "修改成功!",
                  type: "success"
                });
                row = data.data;
              } else {
                _this.$message({
                  showClose: true,
                  message: data.msg ? data.msg : "修改失败!",
                  type: "error"
                });
              }
            });
          }
        }
      },
      //表格中开关
      onSwitch(item, row) {
        let _this = this;
        if (item.opt && item.opt.on && !item.opt.isNotDelay) {
          let lock = false;
          let ids = this.cache.getR("tableSwitch", []);
          for (let i = 0; i < ids.length; i++) {
            const id = ids[i];
            if (row.id == id) {
              lock = true;
              break;
            }
          }
          if (lock) {
            return this.$message({
              message: "操作频繁，请稍后再试",
              type: "warning"
            });
          } else {
            ids.push(row.id);
            this.cache.setR("tableSwitch", ids);
            let _delayTime =
              typeof item.opt.delayTime != "undefined" ?
              item.opt.delayTime :
              3000;

            setTimeout(() => {
              let ids = this.cache.getR("tableSwitch");
              ids.splice(ids.indexOf(row.id), 1);
              this.cache.setR("tableSwitch", ids);
            }, 3000);
            _this.isLoading = true;
            item.opt.on(row).then(data => {
              _this.isLoading = false;
              if (data.state) {
                _this.$message({
                  showClose: true,
                  message: data.msg ? data.msg : "修改成功!",
                  type: "success"
                });
                row[item.key] = !row[item.key];
              } else {
                _this.$message({
                  showClose: true,
                  message: data.msg ? data.msg : "修改失败!",
                  type: "error"
                });
              }
            });
          }
        }
      },
      onSwitch1(item, row,action) {
        if(item.opt && item.opt.change) {
          action.change(row, data => {
            if (data.result) {
                this.$message({
                  showClose: true,
                  message: data.msg ? data.msg : "修改成功!",
                  type: "success"
                });
                row = data.data;
              } else {
                this.$message({
                  showClose: true,
                  message: data.msg ? data.msg : "修改失败!",
                  type: "error"
                });
              }
          })
        }
      },
      tableRowClassName({
        row,
        rowIndex
      }) {
        if (row && row.bk) {
          let className = "";
          switch (row.bk) {
            case 1:
              className = "success-row";
              break;
            case 2:
              className = "warning-row";
              break;
            case 3:
              className = "danger-row";
              break;
            case 4:
              className = "danger-rows";
              break;
            default:
              break;
          }
          return className;
        } else {
          return "";
        }
      },
      // 点击搜索时间筛选按钮触发
      onSelect(timeType) {
        this.searchInps.forEach((item, idx) => {
          if (item.type == "btnGroup") {
            var now = new Date(); //当前日期
            if (timeType === "day") {
              this.searchForm.date = [
                new Date(this.format(now).slice(0, 11) + "00:00:00"),
                new Date(this.format(now).slice(0, 11) + "23:59:59")
              ];
            } else if (timeType === "week") {
              var date = new Date();
              var weekday = date.getDay() || 7;
              date.setDate(date.getDate() - weekday + 1);
              this.searchForm.date = [
                new Date(this.format(date).slice(0, 11) + "00:00:00"),
                new Date(this.format(now))
              ];
            } else if (timeType === "lastWeek") {
              let _this = this;
              getDay(-7);
              this.searchForm.date = [
                new Date(this.format(getDay(-7)).slice(0, 11) + "00:00:00"),
                new Date(this.format(now))
              ];

              function getDay(day) {
                var today = new Date();
                var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
                today.setTime(targetday_milliseconds); //注意，这行是关键代码
                var tYear = today.getFullYear();
                var tMonth = today.getMonth() + 1;
                var tDate = today.getDate();
                if (tMonth < 10) {
                  tMonth = '0' + tMonth
                }
                if (tDate < 10) {
                  tDate = '0' + tDate
                }
                console.log('最近一周： ' + tYear + "-" + tMonth + "-" + tDate)
                var lastWeekFirstDaty = tYear + "-" + tMonth + "-" + tDate;
                return lastWeekFirstDaty

              }

              function doHandleMonth(month) {
                var m = month;
                if (month.toString().length == 1) {
                  m = "0" + month;
                }
                return m;
              }
            } else if (timeType === "month") {
              var Nowdate = new Date();
              var MonthFirstDay = new Date(
                Nowdate.getFullYear(),
                Nowdate.getMonth(),
                1
              );
              var M = Number(MonthFirstDay.getMonth()) + 1;
              var monthFirstDay =
                MonthFirstDay.getFullYear() +
                "-" +
                M +
                "-" +
                MonthFirstDay.getDate();
              this.searchForm.date = [
                new Date(this.format(monthFirstDay).slice(0, 11) + "00:00:00"),
                new Date(this.format(now))
              ];
            } else if (timeType === "lastMonth") {
              var nowdays = new Date();
              var year = nowdays.getFullYear();
              var month = nowdays.getMonth();
              if (month == 0) {
                month = 12;
                year = year - 1;

              }
              if (month < 10) {
                month = '0' + month;
              }

              var myDate = new Date(year, month, 0);

              var startDate = year + '-' + month + '-01 00:00:00'; //上个月第一天
              var endDate = year + '-' + month + '-' + myDate.getDate() + ' 23:59:59'; //上个月最后一天
              this.searchForm.date = [
                startDate,
                endDate
              ];
            }

            // 改变按钮组点击颜色
            for (let i = 0; i < item.buts.length; i++) {
              let butsItem = item.buts[i];
              if (butsItem.timeType == timeType) {
                butsItem.type = "success";
              } else if (item.buts[i].type == "success") {
                butsItem.type = "primary";
              }
            }
            this.onSearch();
          }
        });
      },
      // 变更搜索下拉框
      onChangeSelect(item) {
        let _this = this;
        this.$forceUpdate(); // 强制刷新dom
        this.$props.opt.search.forEach(data => {
          if (data.opt && data.opt.parent && data.opt.parent == item.key) {
            data.disabled = false;
            if (_this.searchForm[data.key]) {
              _this.searchForm[data.key] = "";
            }
          }
        });
        if (item && item.opt && item.opt.change) {
          item.opt.change(this.searchForm[item.key], data => {
            this.$props.opt.search.forEach(res => {
              if (res.key == data.key) {
                _this.searchForm[res.key] = data.value;
              }
            });
          });
        }
      },
      /**
       * 输入框清除按钮
       */
      handleClear(item) {
        if (item.opt && item.opt.clear) {
          item.opt.clear(this.searchForm);
        }
      },
      //远程搜索选择框
      handleSelectRemoteFilterable(item) {
        return keyword => {
          item.opt.on(keyword, data => {
            item.opt.list = data;
          });
        };
      },
      //远程搜索选择框获取焦点
      handleSelectRemoteFilterableFocus(item) {
        if (item.opt.list.length == 0) {
          item.opt.on("", data => {
            item.opt.list = data;
          });
        }
      },
      //远程搜索选择框值改变
      handleSelectRemoteFilterableChange(item) {
        if (item.opt && item.opt.change) {
          item.opt.change(this.form[item.key], data => {
            item.opt.list = data;
          });
        }
      },
      //搜索栏远程搜索选择框值改变
      handleSearchSelectRemoteFilterableChange(item) {
        this.$forceUpdate(); // 强制刷新dom
        if (item && item.opt && item.opt.change) {
          item.opt.change(this.searchForm[item.key], data => {
            this.$props.opt.search.forEach(res => {
              if (res.key == data.key) {
                this.searchForm[res.key] = data.value;
              }
            });
          });
        }
      },
      //导出表格内容
      onExport(item) {
        let config = {};
        let dto = {};
        if (item.listName == "订单列表") {
          dto = {
            phoneNumber: this.searchForm.phone ? this.searchForm.phone : null,
            roomNo: this.searchForm.roomNo ? this.searchForm.roomNo : null,
            payState: this.searchForm.status ? this.searchForm.status : null,
            userName: this.searchForm.name ? this.searchForm.name : null,
            orderNo: null,
            orderType: this.searchForm.orderType ? this.searchForm.orderType : null,
            apartmentId: this.searchForm.apartmentId ?
              this.searchForm.apartmentId : null,
            startDate: this.searchForm.passTime ?
              this.format(this.searchForm.passTime[0]) : null,
            endDate: this.searchForm.passTime ?
              this.format(this.searchForm.passTime[1]) : null
          };
        } else if (item.listName == "售货订单") {
          dto = this.searchForm;
          dto.createTimeBegin = this.searchForm['date'] ? this.format(this.searchForm['date'][0]) : null;
          dto.createTimeEnd = this.searchForm['date'] ? moment(this.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") : null;
        }else if(item.listName == "货柜列表"){
            dto = this.searchForm;
            let type = this.cache.get('containerTypeVal');
            let tenantId = this.cache.get('bindPlatformTypeVal');
            dto.tenantId = tenantId ? Number(tenantId) : 1
            if(type == 4) {//L21BD 
              dto.type = 6;
              dto.size = 1;
            }else if(type == 5) {//L21BX
              dto.type = 6;
              dto.size = 2;
            }else if(type == 6) {//L22BD 
              dto.type = 7;
              dto.size = 1;
            }else if(type == 7) {//L22BX
              dto.type = 7;
              dto.size = 2;
            }else { // 其他柜门
              dto.type = type ? Number(type) : 3;
            }
        }else if(item.listName == '入库列表') {
            dto = this.searchForm;
            dto.inStartDate =this.searchForm['date'] ? this.searchForm['date'][0] : null;
            dto.inEndDate = this.searchForm['date'] ? this.searchForm['date'][1] : null;
        }else if(item.listName == '出库列表') {
            dto = this.searchForm;
            dto.outStartDate =this.searchForm['date'] ? this.searchForm['date'][0] : null;
            dto.outEndDate = this.searchForm['date'] ? this.searchForm['date'][1] : null;
        }else if(item.listName == '出货记录') {
            dto = this.searchForm;
            dto.inStartDate =this.searchForm['date'] ? this.searchForm['date'][0] : null;
            dto.inEndDate = this.searchForm['date'] ? this.searchForm['date'][1] : null;
        }else if(item.listName == '库存列表') {
            dto = dto = this.searchForm;
        }else if(item.listName == '退还仓库记录') {
            dto = this.searchForm;
            dto.startTime = this.searchForm['date'] ? this.format(this.searchForm['date'][0]) : null;
            dto.endTime = this.searchForm['date'] ? moment(this.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") : null;
        }else if(item.listName == '在售列表') {
            dto = this.searchForm;
            dto.startTime = this.searchForm['date'] ? this.searchForm['date'][0] : null
            dto.endTime = this.searchForm['date'] ? this.searchForm['date'][1] : null
        }else if(item.listName == '缺货列表') {
            dto = this.searchForm;
        }else if(item.listName == '提现记录') {
            dto = this.searchForm;
            dto.withdrawStartTime = this.searchForm['startTime'] ? this.format(this.searchForm['startTime'][0]) : null;
            dto.withdrawEndTime = this.searchForm['startTime'] ? this.format(this.searchForm['startTime'][1]) : null;
            dto.realStartTime = this.searchForm['realAmount'] ? this.format(this.searchForm['realAmount'][0]) : null;
            dto.realEndTime = this.searchForm['realAmount'] ? this.format(this.searchForm['realAmount'][1]) : null;
        }else if(item.listName == '供应商收益记录') {
          dto = this.searchForm;
          dto.closeStartTime = this.searchForm['date'] ? this.format(this.searchForm['date'][0]) : null;
          dto.closeEndTime = this.searchForm['date'] ? moment(this.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") : null;
        }else if(item.listName == "商家收益记录") {
          dto = this.searchForm;
          dto.roleType = '1'
          dto.closeStartTime = this.searchForm['date'] ? this.format(this.searchForm['date'][0]) : null;
          dto.closeEndTime = this.searchForm['date'] ? moment(this.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") : null;
        }else if(item.listName == "经销商收益记录") {
          dto = this.searchForm;
          dto.roleType = '3'
          dto.closeStartTime = this.searchForm['date'] ? this.format(this.searchForm['date'][0]) : null;
          dto.closeEndTime = this.searchForm['date'] ? moment(this.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") : null;
        }else if(item.listName == "品类列表") {
          dto = this.searchForm;
        }else if(item.listName == "退款列表") {
          dto = this.searchForm;
        }else if(item.listName == "商品管理列表") {
          dto = this.searchForm;
        }else if(item.listName == '活动任务') {
          dto = this.searchForm;
          dto.startDate = this.searchForm['date'] ? this.format(this.searchForm['date'][0]) : null
          dto.endDate = this.searchForm['date'] ? moment(this.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") : null
        }else if(item.listName == '绑定记录' || item.listName.indexOf("门锁信息导出表") !== -1) {
          dto = this.searchForm;
          if(!dto.apartmentId){
            this.$message({
              showClose: true,
              message: "仅支持单个房源导出，请先选择房源",
              type: "warning"
            });
            return;
          }
          this.apartmentList.forEach(val => {
            if(val.value == dto.apartmentId) {
              item.listName = val.label + '_门锁信息导出表'
            }
          })
        }else if(item.listName == '租约列表' || item.listName.indexOf("_云数平台租约信息导出表") !== -1) {
          dto = this.searchForm;
          dto.signStartDate = this.searchForm.contractorTime ? this.format(this.searchForm.contractorTime[0], 1) : null
          dto.signEndDate = this.searchForm.contractorTime ? this.format(this.searchForm.contractorTime[1], 1) : null
          dto.startDate = this.searchForm.rentTime ? this.format(this.searchForm.rentTime[0], 1) : null
          dto.endDate = this.searchForm.rentTime ? this.format(this.searchForm.rentTime[1], 1) : null
          dto.leaseState = this.searchForm.rentStatus ? this.searchForm.rentStatus : null
          //自订长租
          if (this.searchForm.rentType == '1') {
            dto.leaseFrom = '1';
            dto.leaseType = '2';
          }
          //自订短租
          else if (this.searchForm.rentType == '2') {
            dto.leaseFrom = '1';
            dto.leaseType = '1';
          }
          //云数排房长租
          else if (this.searchForm.rentType == '3') {
            dto.leaseFrom = '2';
            dto.leaseType = '2';
          }
          //云数排房短租
          else if (this.searchForm.rentType == '4') {
            dto.leaseFrom = '2';
            dto.leaseType = '1';
          }
          //自订短租
          else if (this.searchForm.rentType == '5') {
            dto.leaseFrom = '1';
            dto.leaseType = '3';
          }
          //PMS排房
          else if (this.searchForm.rentType == '6') {
            dto.leaseFrom = '3';
            dto.leaseType = '4';
          }
          if(!dto.houseId){
            this.$message({
              showClose: true,
              message: "仅支持单个房源导出，请先选择房源",
              type: "warning"
            });
            return;
          }
          this.apartmentList.forEach(val => {
            if(val.value == dto.houseId) {
              item.listName = val.label + '_云数平台租约信息导出表'
            }
          })
        }else if(item.listName.indexOf('开门日志') != -1) {
           dto = {...this.searchForm,...item.dto};
           dto.beginTime = this.searchForm.date ? this.searchForm.date[0] : "";
           dto.endTime = this.searchForm.date ? this.searchForm.date[1] : "";
           
           if(dto.beginTime && dto.endTime) {
              let allowExportFlag = this.compareTime(6,dto.beginTime,dto.endTime)
              if(allowExportFlag) {
                return this.$message({
                    showClose: true,
                    message: "导出数据时间跨度不能超过6个月",
                    type: "warning"
                });
              }
              let beforeOneYearFlag = this.beforeOneYearDate(dto.beginTime,dto.endTime)
              if(beforeOneYearFlag) {
                return this.$message({
                    showClose: true,
                    message: "历史数据超过1年不支持导出",
                    type: "warning"
                });
              }
           }
        }else if(item.listName.indexOf('开锁日志') != -1) {
            dto = {...this.searchForm,...item.dto};
            dto.beginActionTime = this.searchForm.date ? this.searchForm.date[0] : "";
            dto.endActionTime = this.searchForm.date ? this.searchForm.date[1] : "";
            if(dto.beginActionTime && dto.endActionTime) {
              let allowExportFlag = this.compareTime(6,dto.beginActionTime,dto.endActionTime)
              if(allowExportFlag) {
                return this.$message({
                    showClose: true,
                    message: "导出数据时间跨度不能超过6个月",
                    type: "warning"
                  });
              }
              let beforeOneYearFlag = this.beforeOneYearDate(dto.beginActionTime,dto.endActionTime)
              if(beforeOneYearFlag) {
                return this.$message({
                    showClose: true,
                    message: "历史数据超过1年不支持导出",
                    type: "warning"
              });
              }
           }
        }

        // 以前返回二进制流文件的处理方法
        // if (item.url) {
        //   axios({
        //       method: "POST",
        //       url: item.url,
        //       responseType: "blob",
        //       data: dto,
        //       headers: {
        //         accessToken: localStorage.getItem("token") || sessionStorage.getItem("token")
        //       }
        //     }).then(response => {
        //       var elink = document.createElement("a");
        //       console.log(elink);
        //       elink.download = item.listName + ".xlsx";
        //       elink.style.display = "none";
        //       var blob = new Blob([response.data]);
        //       elink.href = URL.createObjectURL(blob);
        //       document.body.appendChild(elink);
        //       elink.click();
        //       document.body.removeChild(elink);
        //     }).catch(error => {});
        // }
        // 现在返回code是0的json文件的处理方法
        if (item.url) {
          axios({
              method: "POST",
              url: item.url,
              data: dto,
              headers: {
                accessToken: localStorage.getItem("token") || sessionStorage.getItem("token")
              }
            }).then(res => {
              console.log(res);
              if(res.data && res.data.code === 0) {
                // 导出中心弹框标志
                this.exportCenterVisible = true;
              }else {
                this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: "warning"
                });
              }
            }).catch(error => {});
        }
      },
      inputTime(val) {
        // 解决点击了时间按钮后再选择时间  时间值显示没有变化的问题
        this.$forceUpdate();
      },
      compareTime(mons,stTime,endTime){
          var st  = new Date(stTime);
          var et = new Date(endTime);
          var st6mon=new Date(st);
          st6mon.setMonth(st6mon.getMonth()+mons);

          let  last6MonDate= new Date(st6mon.getTime()-24*60*60*1000); 
          let year = last6MonDate.getFullYear();
          let month = last6MonDate.getMonth() + 1;
          let day = last6MonDate.getDate();
          let bb = `${year}-${month}-${day}`
          console.log('合理的末尾时间：',bb)
          
          if(et.getTime()>last6MonDate){
          　 //末位时间超时起始时间6个月
      　　　　return true;
          }
      　　return false;
          //ps:可以用 if(et >(或<) st6mon)，但比较if(et==st6mon)永远返回false，可能==返回的是对象地址引用，所以用getTime()
      },
      beforeOneYearDate(stTime,endTime) {
          let st  = new Date(stTime);
          let et = new Date(endTime);
          let nowDate = new Date();
          nowDate.setMonth(nowDate.getMonth()-12);//一年前的今天
          console.log('一年前的今天nowDate:',moment(nowDate).format('YYYY-MM-DD'))
          if(st.getTime() <= nowDate.getTime() || et.getTime() <= nowDate.getTime()) {
            return true
          }else {
            return false
          }

      },
      // 前往导出中心
      goToExportCenterList() {
        this.exportCenterVisible = false;
        this.$router.push("/main/exportCenter/list")
      },
    }
  };
</script>
<style lang="scss">
  .table-body {
    .el-table__header-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      .cell {
        white-space: inherit;
      }
    }

    .el-table__body-wrapper {
      height: 100%;
      overflow-y: auto;
    }
  }
  
</style>
<style scoped lang="scss">
  .app-list {
    display: flex;
    flex-direction: column;
    height: 100%;

    .table-box {
      position: relative;
      flex: 1;
      overflow-y: auto;
    }

    .table-body {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      padding-top: 48px;
      width: 100%;
    }

    .custom_button {
      float: right;
      margin-right: 20px;
    }

    .title-bar {
      // margin-bottom: 20px;
      height: 40px;
      line-height: 40px;

      .title {
        font-size: 18px;
        color: #303133;
      }

      .refresh {
        float: right;
      }
    }

    .buts-bar {
      // padding: 6px 0;
      display: flex;

      .refresh {
        position: relative;
        // top: 0px;
        // right: 0;
      }

      .onExport {
          position: relative;
          // top: 6px;
          // right: 50px;
          cursor: pointer;
          z-index: 1;
      }
    }

    .row-nav {
      cursor: pointer;
      color: #30BAC1;
    }

    .row-disable {
      color: #cccccc;
    }

    .row-switch-box {
      position: relative;
      display: inline-block;
    }

    .row-switch-but {
      position: absolute;
      z-index: 999;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    .el-pagination {
      margin-top: 12px;
    }

    .el-form--inline {
      .el-form-item {
        margin-right: 14px;
        margin-bottom: 16px !important; 
      }
    }

    .row-nav-vice {
      border-bottom: solid 1px #cccccc;
      cursor: pointer;
    }

    .danger {
      color: #f56c6c;
    }

    .el-checkbox {
      padding: 3px 0;
      width: 22px;
    }

    .search-refresh {
      float: right;
    }

    .row-action-but {
      color: #30BAC1;
      cursor: pointer;
      margin-right: 10px;
    }

    .row-action-baclk {
      color: #666;
      margin-right: 10px;
    }

    .nowrap {
      white-space: nowrap !important;
    }

    .row-img {
      img {
        position: absolute;
        top: 4px;
        left: initial;
        width: 60px;
        height: 80%;
        cursor: pointer;
      }
    }

    .row-img-shop {
      width: 60px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 60px;
        // overflow: hidden;
        // vertical-align: middle;
        // height: 75px;
        cursor: pointer;
      }
    }

    .row-list {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .row-list-group {
      display: flex;
      justify-content: space-around;
      flex-direction: row;

      .row-list-group_item {
        text-align: center;
      }
    }

    .row-action-alt {
      .active {
        color: #666;
      }

      .inactive {
        color: #30BAC1;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .app-list {
      height: 880px;
    }
  }
</style>